import { Controller } from "stimulus"
import axios from 'axios'
//import { timingSafeEqual } from "crypto";

export default class extends Controller {
  static targets = [ 'surveyor', 'password', 'designationSection', 'designation',
                     'qualificationDate', 'ricsNumber', 'competencies', 'showPassword',
                     'homebuyersNumber', 'registrationNumber', 'vrsDate', 'desigInfo',
                     'surveyorSection', 'selectedOrg', 'salesSection']
  static values = { privateAccount: Boolean }

  connect(){
    if (this.hasDesignationTarget) {
      this.toggleDesignationDropdown()
      this.toggleDesignation()
      this.toggleHomebuyers(this.competencies[3])
    }
  }

  get designationSection() {
    return this.designationSectionTarget
  }

  get homebuyersNumber() {
    return this.homebuyersNumberTarget
  }

  get competencies() {
    return this.competenciesTargets
  }

  toggleSection(section, style) {
    section.style.display = style
  }

  toggleDesignation() {
    if (this.hasSurveyorTarget && this.surveyorTarget.checked == true) {
      this.toggleSection(this.designationSection, 'block')
      this.toggleHomebuyers(this.competencies[3])
      this.toggleTextMessageClass('d-none', 'd-block')
    } else {
      this.toggleSection(this.designationSection, 'none')
      this.toggleTextMessageClass('d-block', 'd-none')
      this.updateValues()
    }
  }

  toggleDesignationOptions(e) {
    let designation = this.designationTarget
    let selectTag = this.selectedOrgTarget
    let privateOrgType = selectTag.options[selectTag.selectedIndex].dataset.private
    if (privateOrgType === 'false') {
      this.showDropdownOnly(designation)
    } else {
      let readonlyAttr = designation.getAttribute('readonly')
      let style = designation.getAttribute('style')
      if (readonlyAttr && style) {
        designation.removeAttribute('readonly')
        designation.removeAttribute('style')
      }
    }
  }

  toggleDesignationDropdown(e) {
    if (this.hasPrivateAccountValue && !this.privateAccountValue) {
      this.showDropdownOnly(this.designationTarget)
    }
  }

  showDropdownOnly(designation) {
    designation.setAttribute('readonly', 'readonly')
    designation.setAttribute('style', 'cursor:pointer')
  }

  addDesignationValue(e) {
    let selectedDesignation = e.target.dataset.value
    this.designationTarget.value = selectedDesignation
  }

  toggleTextMessageClass(none, block) {
    this.desigInfoTarget.classList.remove(none)
    this.desigInfoTarget.classList.add(block)
  }

  updateValues() {
    this.designationTarget.value = ''
    this.competencies.forEach(function(competency) {
      if(competency.checked)
        competency.checked = false
    })
    this.ricsNumberTarget.value =  ''
    this.qualificationDateTarget.value = ''
    this.registrationNumberTarget.value = ''
    this.vrsDateTarget.value = ''
  }

  togglePassword() {
    var elem = this.passwordTarget
    if (this.showPasswordTarget.checked) {
      elem.type = 'text'
    } else {
      elem.type = 'password'
    }
  }

  toggleHomebuyers(event) {
    let e
    e = (event.target != undefined) ? event.target : event
    if (e.value == 'homebuyers') {
      if (e.checked) {
        this.toggleSection(this.homebuyersNumber, 'block')
        this.registrationNumberTarget.value = ''
      } else {
        this.toggleSection(this.homebuyersNumber, 'none')
      } 
    }
  }

  toggleSurveyorOption(){
    let selectTag = this.selectedOrgTarget
    let orgType = selectTag.options[selectTag.selectedIndex].dataset.org
    if (orgType == 'true') {
      this.toggleCheckbox('d-none', 'd-block', this.surveyorSectionTarget)
      this.toggleCheckbox('d-block', 'd-none', this.salesSectionTarget)
    } else {
      this.toggleCheckbox('d-block', 'd-none', this.surveyorSectionTarget)
      this.toggleCheckbox('d-none', 'd-block', this.salesSectionTarget)
    }
  }

  toggleCheckbox(show, hide, section) {
    section.classList.add(show)
    section.classList.remove(hide)
    this.updateValues()
    this.surveyorTarget.checked = false
  }
}
