import { Controller } from "stimulus"

export default class extends Controller {

  checkFile(e) {
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/tiff', 'image/bmp', 'image/gif',
      'application/msword', 'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ]
    const maxFileSize = 15728640 // file size 15 MB
    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault();
      alert("file type not supported")
    } else if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert("File attachment are supported upto size 15MB")
    }
  }
}
