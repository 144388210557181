import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "outputImage", "oldImage"]

  get outputImageSection() {
    return this.outputImageTarget;
  }

  get oldImageSection() {
    return this.oldImageTarget;
  }

  previewImage(e){
    if(this.hasoldImageTarget){
      this.oldImageSection.style.display = "none"
      let outputImage = this.outputImageSection
      outputImage.style.width = "100px"
      outputImage.style.height = "100px"
      outputImage.src = window.URL.createObjectURL(e.target.files[0]) 
    }
  }
}
