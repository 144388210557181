import { Controller } from 'stimulus'
import { DataTable } from 'simple-datatables'

export default class extends Controller {
  static targets = ['surveyTypesList', 'organization', 'template']
  static values = { url: String }

  connect() {
    $(document).ready(() => {
      this.dataTable()
    })
  }

  applyFilter(event){
    this.resetOptions(event)
    let url = new URL(this.urlValue)
    url.searchParams.append('organization', this.organizationTarget.value)
    url.searchParams.append('template', this.templateTarget.value)
    this.showResponse(url)
  }
  
  resetOptions(event){
    if(event.target == this.templateTarget){
      this.organizationTarget.value = ''
    }else{
      this.templateTarget.value = ''
    }
  }
  
  showResponse(requestUrl){
    let target = this.surveyTypesListTarget

    fetch(requestUrl, {
      headers: { 'Content-Type': 'text/html' }
    })
    .then((response) => {
      return response.text()
    })
    .then((html) => {
      target.innerHTML = html
      this.dataTable()
    })
  }

  dataTable() {
    let dataTable = new DataTable('#surveyTypesTable', {})
  }
}
