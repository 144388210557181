import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    $('[data-toggle="popover"]').popover({
      placement : 'right',
      trigger : 'hover'
    });
  }
}
