import { toggleClasses } from '../../lib/helper_functions'

export const QuotationSurveyType = {
  /* This method is used to get new alternative survey type form fields with new data field attributes.
  So in this method we're replacing the form object_id, alternative survey type name and id attributes with new attributes in incremented order. */

  getAlternativeSurveyTypeFields(e) {
    let nextAlternativeFormNum = parseInt(e.target.dataset.nextAlternative)
    let link = e.target
    let linkId = link.dataset.id

    // Create a new regular expression needed to find any instance of the `quot_survey_type_obj.object_id` used in the fields data attribute
    let regexp = new RegExp(linkId, 'g')

    let alternativeId = new RegExp(`alternative${link.dataset.alternativeNum}`, 'g')
    let alternativeName = new RegExp(`Alternative Survey Type ${link.dataset.alternativeNum}`, 'g')

    // Replace all instances of the `quot_survey_type_obj.object_id` with `nextAlternativeFormNum`, and save markup into a variable
    let newFields = link.dataset.fields.replace(regexp, link.dataset.nextAlternative)

    newFields = newFields.replace(alternativeId, `alternative${nextAlternativeFormNum}`)
    newFields = newFields.replace(alternativeName, `Alternative Survey Type ${nextAlternativeFormNum}`)
    // Add the new markup to the form
    link.insertAdjacentHTML('beforebegin', newFields)

    if (nextAlternativeFormNum > 3) {
      toggleClasses(this.addSurveyTypeTarget, 'd-block', 'd-none')
    } else {
      e.target.dataset.nextAlternative = nextAlternativeFormNum + 1
    }
  }
}
