import { Controller } from "stimulus"
import axios from 'axios'
import InfoboxController from './infobox_controller'
import PanelFeatureController from './panel_feature_controller'

export default class extends Controller {
  static targets = [ "mapData", "panelData", "clickInstruction", "clickAwaitingInstruction",
                     "clickAssignInstruction", "clientChoice", "dateChoice",
                     "instructionType", "statusChoice", "piChoice", "clickPendingInstruction",
                     "competency", "radius", "panelInstruction", "flag", "clientPanel", "panelSearch",
                     "postcode", "officeRadius", "range", "postcodeRange", "competencyCheckbox",
                     "coverageCheckbox", "selectedFirm"
                   ]

  connect() {
    this.postcodeRangeTarget.style.display = "none"
    this.toggleLoader("block")
    this.markers = []
    this.officemarkers = []
    this.shopwMap( -0.999, 53.000)
    this.instructionId = null
    this.infobox = new InfoboxController()
    this.infobox.connect()
    this.panelFeature = new PanelFeatureController()
    this.disableClientPanel()
    this.toggleLoader("none")
  }

  get url() {
    return this.clickInstructionTarget.dataset.url
  }

  get accountClient() {
    return this.clientChoiceTarget.value
  }

  get instructionDate() {
    return this.dateChoiceTarget.value
  }

  get instructionType() {
    return this.instructionTypeTarget.value
  }

  onMapLoaded() {
    const locations = this.mapDataTarget.dataset.locations
    const instructLocations = this.mapDataTarget.dataset.instructLocation
    const selectedLocation = this.mapDataTarget.dataset.selectedInstruction
    const holdFirmLocations = this.mapDataTarget.dataset.holdFirmLocations
    this.map.addSource("polygon", this.infobox.createGeoJSONCircle([-0.999, 53.000], 40))
    this.addSourceOnMap(locations, instructLocations, selectedLocation, holdFirmLocations)
    let windowUrl = new URL(window.location.href)
    let searchParams = new URLSearchParams(windowUrl.search)
    if (searchParams.has("job_id")) {
      this.clickAwaitingInstructionTarget.click()
      document.getElementById("client-based-office").checked = "checked"
      document.getElementById("office-competency").checked = "checked"
      document.getElementById("office-coverage").checked = "checked"
      document.getElementById("matiching-pi").checked = "checked"
      document.getElementById("active-firms").checked = "checked"
      this.instructionId = windowUrl.href.split("job_id=").pop()
      this.displayRadiusOnMap(JSON.parse(selectedLocation)[0])
    }
    this.redirectToSelectedJobList(searchParams, windowUrl)
  }

  displayRadiusOnMap(instruction) {
    let coordinates = instruction.geometry.coordinates
    let radius = this.fetchRadius(instruction)
    this.panelFeature.flyToPanel(this.map, coordinates)
    this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(coordinates, radius).data)
    this.infobox.createRadiusArea(this.map)
  }

  redirectToSelectedJobList(searchParams, windowUrl) {
    if (searchParams.has("case_id")) {
      if (searchParams.get("pending") == "false") {
        this.clickAssignInstructionTarget.click()
      }
      else if (searchParams.get("pending") == "true") {
        this.clickPendingInstructionTarget.click()
      }
      searchParams.delete('pending')
      history.replaceState(null, '', '?' + searchParams + windowUrl.hash)
    }
  }

  addSourceOnMap(locations, instructLocations, selectedLocation, holdFirmLocations) {
    const geojson = {
     'type': 'FeatureCollection',
     'features': JSON.parse(locations),
     'is_office': true
    }
    const instructiongeojson = {
      'type': 'FeatureCollection',
      'features': JSON.parse(instructLocations)
    }

    const selectedjobgeojson = {
      'type': 'FeatureCollection',
      'features': JSON.parse(selectedLocation)
    }

    const holdFirmgeojson = {
      'type': 'FeatureCollection',
      'features': JSON.parse(holdFirmLocations),
      'hold_sus': true
    }

    this.panelFeature.setSourceOnMap(this.map, geojson, instructiongeojson,
                                     selectedjobgeojson, holdFirmgeojson);

    if (!window.location.href.includes("job_id")) {
      this.addMarkerOnMap(geojson)
      this.addMarkerOnMap(holdFirmgeojson)
      this.buildLocationList(geojson)
      this.buildLocationList(holdFirmgeojson)
    }
  }

  addInstructionMarkerOnMap(instructiongeojson) {
    this.panelFeature.removeMakersfromMap(this.markers)
    let markerClass = "instruct-marker"
    this.createClickableElement(instructiongeojson, markerClass)
  }

  addSelectedJobMarkerOnMap(selectedjobgeojson) {
    if (this.to_unassign == true || this.pending == true) {
      this.panelFeature.removeMakersfromMap(this.markers)
    }
    let markerClass = "selected-job-marker"
    this.createClickableElement (selectedjobgeojson, markerClass)
  }

  createClickableElement(jobgeojson, markerClass) {
    jobgeojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = markerClass
      // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      let mark = this.panelFeature.createMarker(this.map, el, marker)
      this.markers.push(mark)

      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, marker, this.map)
      })

      return el.onclick = (event) => {
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
        this.infobox.createRadiusArea(this.map)
        if (document.getElementById('listings') && !jobgeojson.is_instruction) {
          return createModal(marker)
        }
        else{
          let radius = this.fetchRadius(marker)
          this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(marker.geometry.coordinates, radius).data)
          this.createInstructionModal(marker)
        }
      }
    })
  }

  addMarkerOnMap(geojson) {
    geojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = 'marker'
      if (geojson.hold_sus){
        el.className = 'ghosted_marker'
      }
      // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      let mark = this.panelFeature.createMarker(this.map, el, marker)
      this.officemarkers.push(mark)

      this.infobox.createInfoboxOnMouseEnterEvent(el, marker, this.map)
      return el.addEventListener('click', (e) => {
        this.change_selected_marker(geojson)
        // 1. Fly to the point
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
        this.add_selected_office_marker(el, geojson)
        // 2. Close all other popups and display popup for clicked store1
        this.infobox.createPopUp(el, marker, this.map)
        return this.createModal(marker)
      })
    })
  }

  addSelectedOfficeMarkerOnMap(data) {
    let selectedOffice = document.querySelectorAll(".selected_office_marker")
    selectedOffice.forEach((x) => {
      x.classList.remove('selected_office_marker')
    })
    const el = document.createElement('div')
    el.className = 'selected_office_marker'
    // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
    // Create the custom markers, set their position, and add to map
    let mark = this.panelFeature.createMarker(this.map, el, data)
    this.officemarkers.push(mark)
    this.infobox.createInfoboxOnMouseEnterEvent(el, data, this.map)
  }

  change_selected_marker(geojson) {
    let selectedOffice = document.querySelectorAll(".selected_office_marker")
    let markerClass = geojson.hold_sus ? "ghosted_marker" : "marker"
    selectedOffice.forEach((x) => {
      x.classList.remove('selected_office_marker')
      x.classList.add(markerClass)
    })
  }

  add_selected_office_marker(el, geojson) {
    let markerClass = geojson.hold_sus ? "ghosted_marker" : "marker"
    el.classList.remove(markerClass)
    el.classList.add("selected_office_marker")
  }

  buildLocationList(data) {
    let currentFeature, i, listings, prop 
    i = 0
    listings = document.getElementById('listings')
    let selectedInstructList = document.getElementById('show-instruct')
    if(listings.length != 0 && data.selected_job != true){
      if (!data.hold_sus){
        $('#listings').html("")
      }
      $('#show-instruct').html("")
    }
    while (i < data.features.length) {
      currentFeature = data.features[i]
      prop = currentFeature.properties
      this.createLinkElement(listings, prop, data, i)
      i++
    }
    this.panelFeature.emptyDivision(data)
    this.panelFeature.instructionFilterDivision(data)
    this.panelFeature.officeFilterDivision(data)
    this.flagTarget.innerHTML = ""
    this.panelFeature.mapFilter(this.unassign)
    this.panelFeature.officeMapFilter(data)
  }

  showSelectedJobList(data) {
    let currentFeature, i, listings, prop
    i = 0
    listings = document.getElementById('listings')
    let selectedInstructList = document.getElementById('show-instruct')
    if(listings.length != 0 && data.selected_job != true){
      $('#listings').html("")
      $('#show-instruct').html("")
    }
    while (i < data.features.length) {
      currentFeature = data.features[i]
      prop = currentFeature.properties
      if (this.to_unassign == true || this.pending == true) {
        this.createUnassignLink(selectedInstructList, prop, data, i)
      }
      else {
        this.createLinkElement(selectedInstructList, prop, data, i)
      }
      i++
    }
    let header = selectedInstructList.appendChild(document.createElement('h5'))
    header.className = 'mt-4 b'
    header.innerHTML = "Remaining instruction list"
    this.panelFeature.emptyDivision(data)
    this.panelFeature.mapFilter(this.unassign)
  }

  designUnassignInstructionLink(link, prop, i) {
    link.href = 'javascript:void(0);'
    link.dataPosition = i
    link.className = 'list-group-item list-group-item-action'
    link.dataset.id = prop.id
    link.id = 'listing-' + prop.id
    link.dataset.target = 'panels.panelData'
    let header = link.appendChild(document.createElement('h5'))
    header.className = 'd-flex justify-content-between mb-0'
    header.innerHTML = prop.title
    let firmName = header.appendChild(document.createElement('p'))
    firmName.className = "small font-weight-bold float-right"
    firmName.innerHTML = prop.firm_name
    let details = link.appendChild(document.createElement('p'))
    details.innerHTML = prop.full_address
    details.className = 'd-flex justify-content-between word-break'

    let unassignbutton = details.appendChild(document.createElement('a'))
    unassignbutton.className = 'btn btn-danger text-center float-right mb-0'
    unassignbutton.dataset.method = 'delete'
    unassignbutton.innerHTML = "Unassign"
    unassignbutton.href = `/admins/instructions/${prop.id}/assignees`
  }

  createUnassignLink(listings, prop, data, i) {
    let link = listings.appendChild(document.createElement('a'))
    this.designUnassignInstructionLink(link, prop, i)
    this.clickData(link, data)
  }

  createLinkElement(listings, prop, data, i) {
    let link = listings.appendChild(document.createElement('a'))
    link.href = 'javascript:void(0);'
    link.dataPosition = i
    link.className = 'list-group-item list-group-item-action'
    link.dataset.id = prop.id
    link.id = 'listing-' + i
    link.dataset.target = 'panels.panelData'
    let header = link.appendChild(document.createElement('h5'))
    header.className = 'mb-1'
    header.innerHTML = prop.title
    if (data.is_instruction ==  true && (this.to_unassign == true || this.pending == true)) {
      this.showFirmName(header, prop)
    }
    // To add lables on office list
    if(data.is_office == true) {
      this.panelFeature.checkStatuses(data, prop, header)
    }
    if( this.unassign == true ) {
      this.createReadyToAssignLink(header, prop, link)
    }
    let details = link.appendChild(document.createElement('p'))
    details.innerHTML = prop.full_address
    if (this.unassign == true) {
      let createdDate = link.appendChild(document.createElement('span'))
      createdDate.className = "small text-center mb-0"
      createdDate.innerHTML = "Created at: " + prop.created_at
    }
    details.className = 'mb-0 word-break'
    this.clickData(link, data)
  }

  createReadyToAssignLink(header, prop, link) {
    let displayId = header.appendChild(document.createElement('span'))
    displayId.className = "font-size-weight text-secondary ml-2 "
    displayId.innerHTML = 'Instruction id: ' + '#' + prop.id
    let instructState = link.appendChild(document.createElement('span'))
    instructState.className = "small font-weight-bold float-right mb-0"
    instructState.innerHTML = prop.instruction_state
  }

  showFirmName(header, prop) {
    let firmName = header.appendChild(document.createElement('p'))
    firmName.className = "small font-weight-bold float-right"
    firmName.innerHTML = prop.firm_name
  }

  clickData(link, data) {
    link.onclick = (event) => {
      const clickedListing = data.features[event.currentTarget.dataPosition]
      this.panelFeature.flyToPanel(this.map, clickedListing.geometry.coordinates)
      if (document.getElementById('listings') && !data.is_instruction) {
        this.addSelectedOfficeMarkerOnMap(clickedListing)
        this.createModal(clickedListing)
      }
      else {
        this.infobox.createRadiusArea(this.map)
        this.createInstructionModal(clickedListing)
        let radius = this.fetchRadius(clickedListing)
        this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(clickedListing.geometry.coordinates, radius).data)
      }
      this.panelFeature.activeItem(event)
    }
  }

  shopwMap(latitude, longitude) {
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v9', //stylesheet location
      center: [latitude, longitude], // starting position
      zoom: 6 // starting zoom
    })
    this.map.addControl(new mapboxgl.NavigationControl())
    this.map.on('load', () =>  { this.onMapLoaded() })
  }

  createModal(currentFeature) {
    if (this.instructionId != null) {
      $.get("/admins/surveying_panels/" + currentFeature.properties.id+"?instruction_id="+this.instructionId)
    }
    else {
      $.get("/admins/surveying_panels/" + currentFeature.properties.id)
    }
  }

  createInstructionModal(currentFeature) {
    /* set clicked instruction id */
    this.instructionId = currentFeature.properties.id
    let type = this.checkedFilterPanel()
    if (this.unassign == true) {
      let url = "/admins/surveying_panels?job_id=" + this.instructionId 
      let instructParams = {
        params:  { unassign: true, instruction: true, selected_ins_id: this.instructionId,
                   clientSpecfic: type[0], competency: type[1],
                   coverage: type[2], pi_specficpending_list: type[3], active: type[4]},
        headers: {'Content-Type':'application/json','Accept':'application/json'}
      }
      this.instructionJsonResponse(url, instructParams)
    }
    if (this.to_unassign == true || this.pending == true) {
      let url = "/admins/surveying_panels?case_id=" + this.instructionId
      let isInstruction = { instruction: true }
      let to_unassign_or_pending_params = {
        params:  Object.assign(isInstruction, this.getParams()),
        headers: {'Content-Type':'application/json','Accept':'application/json'}
      }
      this.instructionJsonResponse(url, to_unassign_or_pending_params)
    }
    $.get("/admins/instructions/" + currentFeature.properties.id +"/instruction_panel")
  }

  getParams() {
    if (this.to_unassign == true) {
      return { assigned_instruction: 'assigned_instruction' }
    }
    else {
      return { pending_list: 'pending_list' }
    }
  }

  allInstructionList(event) {
    this.panelSearchTarget.value = ""
    $('#listings').html("")
    let attribute = event.currentTarget.getAttribute('id')
    let params = this.instructionParams(attribute)
    let instructParams = {
      params:  params,
      search: this.panelSearchTarget.value,
      headers: {'Content-Type':'application/json','Accept':'application/json'}
    }
    this.instructionJsonResponse (this.url, instructParams)
    if (this.map.getLayer("polygon") && this.map.getSource("polygon")) {
      this.map.setLayoutProperty('polygon', 'visibility', 'none')
    }
  }

  setStaticVariableForJobList(allInstruction, unassignInstruction, insToUnassign, pending) {
    this.all_ins = allInstruction
    this.unassign = unassignInstruction
    this.to_unassign = insToUnassign
    this.pending = pending
  }

  instructionParams(attribute) {
    let instructions_params
    switch(attribute) {
      case 'instruction-list':
        this.setStaticVariableForJobList(true, false, false, false)
        return { all_instruction: 'all_instruction', instruction: true }
      case 'assigned-instruction-list':
        this.setStaticVariableForJobList(false, false, true, false)
        return { assigned_instruction: 'assigned_instruction', instruction: true }
      case 'unassigned-list':
        this.setStaticVariableForJobList(false, true, false, false)
        return { unassign: true, instruction: true }
      case 'pending-list':
        this.setStaticVariableForJobList(false, false, false, true)
        return { pending_list: 'pending_list', instruction: true }
    }
  }

  officeMarkerOnMap(activeOffice, holdFirmOffice) {
    this.addMarkerOnMap(activeOffice)
    this.addMarkerOnMap(holdFirmOffice)
  }

  instructionJsonResponse(url, instructParams ) {
    this.toggleLoader("block")
    axios.get(url, instructParams)
      .then((response)=> {
        $('#listings').html("")
        let data = {'type': 'FeatureCollection', 'features': response.data.instruct_locations, 'is_instruction': true}
        let selectedJobData = {'type': 'FeatureCollection', 'features': response.data.selected_instruction, 'is_instruction': true, 'selected_job': true}
        let officeData = {'type': 'FeatureCollection', 'features': response.data.locations}
        let holdFirm = {'type': 'FeatureCollection', 'features': response.data.hold_firm_locations, 'hold_sus': true}
        this.panelFeature.removeMakersfromMap(this.officemarkers)
        this.officeMarkerOnMap(officeData, holdFirm)
        this.addInstructionMarkerOnMap(data)
        this.buildLocationList(data)
        let responseUrl = response.request.responseURL
        if (responseUrl.includes('unassign') ||
            responseUrl.includes('assigned_instruction') ||
            responseUrl.includes('pending_list')) {
          if (selectedJobData.features != undefined && selectedJobData.features.length > 0) {
            this.addSelectedJobMarkerOnMap(selectedJobData)
            this.showSelectedJobList(selectedJobData)
            this.panelFeature.removeMakersfromMap(this.officemarkers)
            this.officeFilter()
          }
        }
        this.toggleLoader("none")
      })
  }

  listLinkClicked() {
    if (this.all_ins == true){
      return { all_instruction: 'all_instruction'}
    } else if (this.to_unassign == true) {
      return { assigned_instruction: 'assigned_instruction' }
    } else if (this.unassign == true) {
      return { unassign: true }
    } else if (this.pending == true) {
      return { pending_list: 'pending_list' }
    }
  }

  filterPanel() {
    let filterParams
    let inst_param =  this.listLinkClicked()
    let search = this.panelSearchTarget.value

    if (this.instructionDate.length > 0){
      filterParams = { create_time: this.instructionDate,
                        search: search }
    }
    if (this.accountClient.length > 0 && this.instructionDate.length > 0){
      filterParams = { client_id: this.accountClient,
                        create_time: this.instructionDate,
                        search: search }
    }
    if (this.instructionType.length > 0){
      filterParams = { client_id: this.accountClient,
                        create_time: this.instructionDate,
                        instruction_type: this.instructionType,
                        search: search }
    }
    filterParams = Object.assign(filterParams, inst_param)
    let clientParams = {
      params:  filterParams,
      headers: {'Content-Type':'application/json','Accept':'application/json'}
    }
    this.instructionJsonResponse(this.url, clientParams)
  }

  // Office status filters
  statusFilter() {
    this.disableClientPanel()
    let panelOfficeSearch = this.panelSearchTarget.value
    let status = this.statusChoiceTarget.value
    let piStatus = this.piChoiceTarget.value
    let competency = this.competencyTarget.value
    let clientAccount = this.clientPanelTarget.value
    let firmAccount = this.selectedFirmTarget.value
    let filterParams = { status: status,
                          pi_status: piStatus,
                          office_competency: competency,
                          client: clientAccount,
                          firm: firmAccount,
                          search: panelOfficeSearch }
    let params = {
      params:  filterParams,
      headers: {'Content-Type':'application/json','Accept':'application/json'}
    }
    this.officeFilterJsonResponse(this.url, params)
  }

  officeFilterJsonResponse(url, instructParams ) {
    this.panelFeature.removeMakersfromMap(this.officemarkers)
    this.toggleLoader("block")
    axios.get(url, instructParams)
      .then((response)=> {
        if(response.data) {
          // client panel client list updates
          this.updateAccountList(response.data.client_list, this.clientPanelTarget)
          // firms list updates
          this.updateAccountList(response.data.firm_list, this.selectedFirmTarget)
        }
        $('#listings').html("")
        let officeData = {'type': 'FeatureCollection', 'features': response.data.locations, 'is_office': true}
        let holdFirm = {'type': 'FeatureCollection', 'features': response.data.hold_firm_locations, 'hold_sus': true, 'is_office': true}
        this.officeMarkerOnMap(officeData, holdFirm)
        this.buildLocationList(officeData)
        this.buildLocationList(holdFirm)
        this.toggleLoader("none")
      })
  }

  // panel filter on map
  officeFilter() {
    if(this.instructionId == null) {
      this.flagTarget.classList ='d-block alert alert-danger mb-0'
      this.flagTarget.innerHTML = "Please select instruction to apply filters"
    } else {
      this.toggleCoverageOption()
      this.flagTarget.classList = "mb-0"
      this.flagTarget.innerHTML = ""
      this.applyOfficeFilter()
    }
  }

  applyOfficeFilter() {
    let type = this.checkedFilterPanel()
    let officeParams = {
      params:  { selected_ins_id: this.instructionId, client_specfic: type[0],
                 competency: type[1], coverage: type[2], pi_specfic: type[3],
                 active: type[4] },
      headers: {'Content-Type':'application/json','Accept':'application/json'}
    }
    if (this.unassign == true){
      this.panelFeature.removeMakersfromMap(this.officemarkers)
    }
    this.toggleLoader("block")
    axios.get(this.url, officeParams)
      .then((response)=> {
        let responseData = response.data
        let data = {'type': 'FeatureCollection', 'features': responseData.locations}
        let holdFirm = {'type': 'FeatureCollection', 'features': responseData.hold_firm_locations, 'hold_sus': true}
        let selectedJobData = {'type': 'FeatureCollection', 'features': responseData.selected_instruction, 'is_instruction': true, 'selected_job': true}
        this.officeMarkerOnMap(data, holdFirm)
        if (selectedJobData.length > 0) {
          this.addSelectedJobMarkerOnMap(selectedJobData)
        }
        this.toggleLoader("none")
      })
  }

  checkedFilterPanel() {
    let clientSpecfic = document.getElementById("client-based-office").checked
    let competency = document.getElementById("office-competency").checked
    let coverage = document.getElementById("office-coverage").checked
    let matchingPI = document.getElementById("matiching-pi").checked
    let active = document.getElementById("active-firms").checked
    return [clientSpecfic, competency, coverage, matchingPI , active]
  }

  panelRadius() {
    let radius = this.radiusTarget
    let coordinates = this.panelInstructionTarget.value
    this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(coordinates, radius.value).data)
  }

  fetchRadius(instruction) {
    const defaultRadius = 25
    this.panelInstructionTarget.value = instruction.geometry.coordinates
    let radius = instruction.properties.panel_radius == null ? defaultRadius : instruction.properties.panel_radius
    this.radiusTarget.value = radius
    return radius
  }

  disableClientPanel() {
   let competencyValue = this.competencyTarget.value
   if (competencyValue == "all" || competencyValue == "homebuyers") {
    this.clientPanelTarget.disabled = true
    this.clientPanelTarget.selectedIndex = 0
   } else {
    this.clientPanelTarget.disabled = false
   }
  }

  updateAccountList(accounts, targetElement) {
    if (accounts == null || accounts.length == 0 || targetElement.value != "") return;

    targetElement.value = ''
    targetElement.innerHTML = ''
    let option = targetElement.appendChild(document.createElement('option'))
    option.innerHTML = 'All'
    option.value = ''
    accounts.forEach((account) => {
      let option = targetElement.appendChild(document.createElement('option'))
      option.value = account['id']
      option.innerHTML = account['name']
    })
  }

  applyPanelSearch(e) {
    if (e.type == "click" || e.keyCode == 13) {
      if (document.getElementById("office-filters").className == "d-none") {
        this.filterPanel()
      } else {
        this.statusFilter()
      }
    }
  }

  officePanelPostcode(e) {
    if (e.type == "click" || e.keyCode == 13) {
      let postcode = this.postcodeTarget.value
      if (postcode != "") {
        this.postcodeRangeTarget.style.display = "block"
      } else {
        this.postcodeRangeTarget.style.display = "none"
      }
      let postcodeParams = {
        params:  { postcode: postcode },
        headers: {'Content-Type':'application/json','Accept':'application/json'}
      }
      this.toggleLoader("block")
      axios.get(`/admins/surveying_panels/postcode_data`, postcodeParams)
        .then((response)=> {
          const el = document.createElement('div')
          el.className = 'postcode-marker'
          let responseData = response.data.data
          if (!this.test_marker == false) {
            this.test_marker.remove()
            this.rangeTarget.value = ""
            this.addRange()
          }
          if (responseData != null) {
            let postcodeCoordinates = [responseData["geometry"]["coordinates"][0], responseData["geometry"]["coordinates"][1]]
            let mark = new (mapboxgl.Marker)(el, { offset: [0,
            -23 ]
            }).setLngLat(postcodeCoordinates).addTo(this.map)
            this.test_marker = mark
            this.panelFeature.flyToPanel(this.map, postcodeCoordinates)
          }
          this.toggleLoader("none")
        })
    }
  }

  addRange() {
    if (this.test_marker != undefined) {
      let range = this.rangeTarget
      let cordinates = [this.test_marker["_lngLat"]["lng"], this.test_marker["_lngLat"]["lat"]]
      this.infobox.createRadiusArea(this.map)
      this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(cordinates, range.value).data)
    }
  }

  toggleLoader(visibility) {
    document.getElementById("spin").style.display = visibility
  }

  toggleCoverageOption() {
    let competency = this.competencyCheckboxTarget
    let coverage = this.coverageCheckboxTarget
    if (competency.checked) {
      coverage.checked = false
      coverage.disabled = true
    } else {
      coverage.disabled = false
    }
  }
}
