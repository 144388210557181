import DragDropController from './drag_drop_controller'

export default class extends DragDropController {
  static targets = ["bookmarkList", "surveyType", "account", "bookmarkId" ]

  dragstart(event) {
    let eventTarget = event.target;
    let targetSection = eventTarget.classList.contains("bm-section")
    if(event.dataTransfer.getData("application/drag-key") == "" && (targetSection || eventTarget.offsetParent.classList.contains("bm-section"))){
      event.dataTransfer.setData("application/drag-key", eventTarget.getAttribute("data-id"))
      event.dataTransfer.effectAllowed = "move"
    }
  }

  drop(event) {
    let eventTarget = event.target;
    let targetSection = eventTarget.classList.contains("bm-section")
    if (eventTarget.offsetParent == undefined) { return }
    if(targetSection || eventTarget.offsetParent.classList.contains("bm-section")){
      let targetElement;
      targetElement = targetSection ? eventTarget : eventTarget.offsetParent
      let draggedID = event.dataTransfer.getData("application/drag-key")
      const dropTarget = targetElement;
      const draggedItem = this.element.querySelector(`[data-id='${draggedID}']`);
      if (draggedItem == undefined) { return }
      let droppedOrder = parseInt(targetElement.getAttribute('data-sequence'))
      if (droppedOrder == 0) { return }
      this.insertTargetElement(dropTarget, draggedItem, targetElement, event);
      this.updateData(droppedOrder, draggedID)
    }
  }

  updateData(droppedOrder, draggedID){
    let account =  this.accountTarget.value;
    let surveyType = this.surveyTypeTarget.value;
    const content = { dropped_position: droppedOrder, dragged_id: draggedID }
    let update_url = `/accounts/${account}/survey_types/${surveyType}/reorder_bookmarks`
    this.updateFetchedData(content, update_url);
  }
}
