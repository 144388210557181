import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ['pageStart']

  connect(){
    this.editable = this.element.getAttribute("data-editable") == 'true';
    this.codes = JSON.parse(this.element.getAttribute("data-codes"));

    let elem = this.pageStartTarget;
    elem.querySelectorAll('textarea').forEach(item => item.disabled = !this.editable);
    this.selectTagOps(elem);
  }

  selectTagOps(elem){
    elem.querySelectorAll('select').forEach(item => {
      item.addEventListener('change', event => {
        this.changeSelectBackground(item);
      })
      item.disabled = !this.editable;
      item.classList.add('avm-select');
      this.changeSelectBackground(item);
    })
  }

  changeSelectBackground(item){
    let color = this.codes[item.value];
    if(color == undefined) {
      item.style.backgroundColor = 'white';
    } else {
      item.style.backgroundColor = color;
      item.querySelectorAll("option")[0].style.backgroundColor = 'white';
    }
  }
}