import { Controller } from "stimulus"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default class extends Controller {
  static targets = ["avmCanvas", "recordId", "avmAddress", "infoText", "pagebreak"]

  get recordID() {
    return this.recordIdTarget.innerText.slice(1)
  }

  get avmAddress(){
    let avmAddress= this.avmAddressTarget.innerHTML;
    return avmAddress.split(', ').join("_").replace(/[ ]/g, '_');
  }
 
  htmlToPDF(doc, canvas, dataURL) {
    let imgWidth = doc.internal.pageSize.getWidth()
    let pageHeight = doc.internal.pageSize.getHeight()
    let imgHeight = canvas.height * imgWidth / canvas.width
    let heightLeft = imgHeight
    let position = 5

    doc.text("Property Assessment Tool", 70, 7);
    doc.addImage(dataURL, 'PNG', 3, position + 5, imgWidth - 10, imgHeight + 15, '', 'FAST')
    heightLeft -= pageHeight

    while (heightLeft > 0) {
      position = heightLeft - imgHeight
      doc.text("Property Assessment Tool", 70, 7);
      doc.addPage();
      doc.addImage(dataURL, 'PNG', 3, position, imgWidth - 10, imgHeight + 15, '', 'FAST')
      heightLeft -= pageHeight
    }

    let pages = doc.internal.getNumberOfPages()
    if (pages > 2) doc.deletePage(pages);
  }

  hideInfoText() {
    this.avmCanvasTarget.querySelectorAll('sup').forEach(item => 
      item.parentElement.dataset.html2canvasIgnore = "true"
    )
  }

  generatePdf(){
    const filename  = `${this.recordID}_${this.avmAddress}.pdf`;
    this.hideInfoText();
    this.pagebreakTarget.style.display = 'block';
    html2canvas(this.avmCanvasTarget, {
    }).then(canvas => {
      let doc = new jsPDF('p', 'mm');
      let dataURL = canvas.toDataURL('image/png');
      this.htmlToPDF(doc, canvas, dataURL)
      doc.save(filename);
    });
    this.pagebreakTarget.style.display = 'none';
  }
}
