import Trix from "trix"
document.addEventListener("trix-initialize", function (event) {
  const trix = Trix.views ? Trix.views : Trix
  trix.AttachmentView.prototype.createCaptionElement = function () {
    const figcaption = document.createElement("figcaption")
    figcaption.classList.add(Trix.config.css.attachmentCaption)
    const caption = this.attachmentPiece.getCaption()
    if (caption) {
      figcaption.classList.add(`${Trix.config.css.attachmentCaption}--edited`)
      figcaption.textContent = caption
    } else {
      let size, name, isImage
      const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "tiff", "bmp", "gif"]
      name = this?.attachment?.getFilename()
      size = this?.attachment?.getFormattedFilesize()
      if(name){
        const extension = name.split(".").pop().toLowerCase()
        isImage = IMAGE_EXTENSIONS.includes(extension)
      }
      if (!isImage) {
        const nameElement = document.createElement("span")
        decorateElement(nameElement, Trix.config.css.attachmentName, name, figcaption)
        if (size) {
          const sizeElement = document.createElement("span")
          decorateElement(sizeElement, Trix.config.css.attachmentSize, size, figcaption)
        }
      }
    }
    return figcaption
  }

  function decorateElement(element, className, content, figcaption) {
    element.className = className
    element.textContent = content
    figcaption.appendChild(element)
  }
})
