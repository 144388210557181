import { Controller } from "stimulus"
import { getMetaValue, applyMixins } from '../lib/helper_functions'
import { UploadsHelper } from './mixins/uploads_helper'

export default class extends Controller {
  static targets = [ 'inspectionFolderTab', 'reportFolderTab', 'inspectionFolderTabLink', 'reportFolderTabLink', 'previewImage' ]

  static values = { url: String, fetchFolderData: Object, maxPhotos: Number, totalFieldPhotos: Number,
                    fieldId: String, fieldUploadsCount: Number, selectedUploads: String }

  connect() {
    applyMixins(this, [UploadsHelper])
    this.fetchFolderDataValue = { 'instruction': false, 'inspection': true, 'report': true, 'plan_and_notes': true, 'inspection_file': true }
    let urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has('folder')){
      this.fetchUploadFolderData(urlParams.get('folder'))
      this.assignFolderDataValue(urlParams.get('folder'))
    }
  }

  renderUploadFolder(e) {
    let folder = e.target.dataset.folder
    // initially the value is set true for fetching the data and once the data is fetched in fetchUploadFolderData, its set to false in assignFolderDataValue. So, that we dont need to refetch the data
    if (folder && this.fetchFolderDataValue[folder]) {
      this.fetchUploadFolderData(folder)
      this.assignFolderDataValue(folder)
    }
  }

  // To upload attachment from inspection to report folder
  addAttachmentToReport(e) {
    const data = e.target.dataset

    if (data.folder === 'report') {
      this.fetchFolderDataValue = Object.assign(this.fetchFolderDataValue, { 'report': true })
    }

    fetch(data.uploadUrl, {
      headers: { 'Content-Type': 'text/javascript', 'X-CSRF-Token': getMetaValue('csrf-token') },
      method: 'POST'
    })

    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .then(data => {
      document.getElementById("upload_" + data).remove()
      document.getElementById("carousel-item-" + data).remove()
    })
  }

  fetchUploadFolderData(folder) {
    let params = { folder: folder }
    if (folder === 'inspection_file') {
      params = { folder: 'inspection', inspection_file: true }
    } else if ((folder === 'inspection') && this.fieldIdValue) {
      params = { folder: folder, transfer_files_from_inspection: true, field_id: this.fieldIdValue, field_uploads_count: this.fieldUploadsCountValue }
    }
    $.get({
      url: this.urlValue,
      data: params,
      headers: { 'Content-Type': 'text/javascript', 'X-CSRF-Token': getMetaValue('csrf-token') }
    })
  }

  // This function used to make folder value false 
  assignFolderDataValue(folder){
    let obj = {}
    obj[folder] = false
    this.fetchFolderDataValue = Object.assign(this.fetchFolderDataValue, obj)
  }

  updateContent(e) {
    let uploadIds = this.selectedUploadsValue.split(',')
    let redirectBackToFieldUrl = e.target.dataset.redirectBackToFieldUrl

    $.post({
      url: e.target.dataset.inspectionUploadUrl,
      data: { upload_ids: uploadIds },
      headers: { 'X-CSRF-Token': getMetaValue('csrf-token') }
    }).done(function() {
      if ( !!redirectBackToFieldUrl ) {
        window.location.href = redirectBackToFieldUrl
      } 
    })
  }

  activateUploadTab() {
    this.inspectionFolderTabLinkTarget.classList.add('active', 'show')
    this.reportFolderTabLinkTarget.classList.remove('active', 'show')
    this.inspectionFolderTabTarget.classList.add('active', 'show')
    this.reportFolderTabTarget.classList.remove('active', 'show')
    this.fetchFolderDataValue = Object.assign(this.fetchFolderDataValue, { 'inspection': true })
    this.selectedUploadsValue = ''
  }

  enableDisableFieldUploads() {
    let remainingUploads = this.maxPhotosValue - this.fieldUploadsCountValue
    let currentChecks = this.selectedUploadsValue.split(',').filter(value => value).length
    let uncheckedCheckboxes = document.querySelectorAll('input[type="checkbox"]:not(:checked)')
    let disabledCheckboxes = document.querySelectorAll('input[type="checkbox"]:disabled').length
    if (currentChecks === remainingUploads) {
      uncheckedCheckboxes.forEach( checkbox => checkbox.disabled = true )
    } else if (((remainingUploads - 1) === currentChecks) && (disabledCheckboxes)) {
      uncheckedCheckboxes.forEach( checkbox => checkbox.disabled = false )
    }
  }

  selectedUploads(e) {
    let previousCheckedUploads = this.selectedUploadsValue.split(',').filter(value => value)

    if (e.target.checked) {
      previousCheckedUploads.push(e.target.id)
    } else {
      let index = previousCheckedUploads.indexOf(e.target.id)
      previousCheckedUploads.splice(index, 1)
    }

    this.selectedUploadsValue = previousCheckedUploads.join(',')
  }

  previewUpload(e) {
    let uploadId = e.target.dataset.uploadId
    this.insertImage(`upload-photo-${uploadId}`, this.previewImageTarget)
    $('#previewUploadModal').modal('show')
  }
}
