import { Controller } from "stimulus"

export default class extends Controller  {

  flyToPanel(map, coordinates) {
    map.flyTo({
      center: coordinates,
      zoom: 8
    })
  }

  createMarker(map, el, marker){
    let mark = new (mapboxgl.Marker)(el, { offset: [
        0,
        -23
      ]
    }).setLngLat(marker.geometry.coordinates).addTo(map);

    return mark;
  }

  activeItem(event){
    const activeItem = document.getElementsByClassName('active');
    if (activeItem[0])
      activeItem[0].classList.remove('active');
    return event.currentTarget.parentNode.classList.add('active');
  }

  emptyDivision(data){
    let emptyDiv = document.getElementById('empty-listings');
    if (document.getElementById("listings").childElementCount > 0) {
      return $(emptyDiv).removeClass('d-block').addClass('d-none');
    } else {
      return $(emptyDiv).removeClass('d-none').addClass('d-block');
    }
  }

  instructionFilterDivision(data){
    let dropDiv = document.getElementById('panel-filters');
    if (data.features.length > 0 && data.is_instruction ==  true) {
      return $(dropDiv).addClass('d-block');
    } else {
      return $(dropDiv).addClass('d-none');
    }
  }

  officeFilterDivision(data){
    let dropDiv = document.getElementById('office-filters');
    if(data.is_instruction == undefined || !data.is_instruction){
      if (data.features.length > 0 && data.is_office == true) {
        return $(dropDiv).addClass('d-block');
      } else {
        return $(dropDiv).addClass('d-none');
      }
    }else{
      $(dropDiv).removeClass('d-block');
    }
  }

  mapFilter(unassign){
    let panelFilterDiv = document.getElementsByClassName('map-filter');
    if ($(panelFilterDiv).hasClass('d-block')){
      $(panelFilterDiv).removeClass('d-block')
    }
    if (unassign == true){
      return $(panelFilterDiv).addClass('d-block');
    } else {
        return $(panelFilterDiv).addClass('d-none');
    }
  }

  displayContent(contentElement, fieldStyle, linkElement, linkStyle){
    contentElement.style.display = fieldStyle;
    linkElement.style.display = linkStyle;
  }

  moreContent(e){
    let contentElement, linkElement; 
    contentElement = e.target.parentElement.nextElementSibling;
    linkElement = e.target.parentElement;
    contentElement.classList.remove('d-none')
    this.displayContent(contentElement, 'block', linkElement, 'none')
  }

  lessContent(e){
    let contentElement, linkElement; 
    contentElement = e.target.parentElement.previousElementSibling;
    linkElement = e.target.parentElement;
    this.displayContent(contentElement, 'block', linkElement, 'none')
  }

  removeMakersfromMap(markers){
    markers.map(m => m.remove());
    markers = [];
  }

  checkPiStatus(span, property){
    if (property.account_hold){
      span.className = "ml-2 badge badge-danger badge-font-size"
      span.innerHTML = "PI Expired";
    }else{
      this.checkPiUpdated(span, property);
    }
  }

  checkAccountStatus(span, property){
    if (!property.account_active){
      span.className = "ml-2 badge badge-warning badge-font-size"
      span.innerHTML = "Suspended";
    }else{
      span.className = "ml-2 badge badge-success badge-font-size"
      span.innerHTML = "Active";
    }
  }

  checkTermsAndPolicies(span, property){
    if (!property.terms){
      span.className = "ml-2 badge badge-info badge-font-size purple-badge"
      span.innerHTML = "T&C not accepted";
    }
  }

  checkInsufficientSurveyors(span, property){
    if (!property.surveyors){
      span.className = "ml-2 badge badge-info badge-font-size amber-flag"
      span.innerHTML = "Insufficient Surveyors";
    }
  }

  checkPiUpdated(span, property){
    if(!property.account_hold){
      span.className = "ml-2 badge badge-success badge-font-size"
      span.innerHTML = "PI Valid";
    }
    if(!property.account_hold && property.pi_updated && property.terms && property.surveyors){
      span.className = "ml-2 badge badge-info badge-font-size"
      span.innerHTML = "Review PI";
    }
  }

  checkStatuses(data, property, header){
    let piStatus = header.appendChild(document.createElement('span'));
    this.checkPiStatus(piStatus, property);

    let accountStatus = header.appendChild(document.createElement('span'));
    this.checkAccountStatus(accountStatus, property);

    let termsAndPolicies = header.appendChild(document.createElement('span'));
    this.checkTermsAndPolicies(termsAndPolicies, property)

    let insufficientSurveyors = header.appendChild(document.createElement('span'));
    this.checkInsufficientSurveyors(insufficientSurveyors, property);

    let competency = header.appendChild(document.createElement('span'));
    let com_element = competency.appendChild(document.createElement('small'));
    com_element.className = 'float-right mb-0 text-capitalize font-size-weight';
    com_element.innerHTML = property.competency.join(',');
  }

  setSourceOnMap(map, geojson, instructiongeojson, selectedjobgeojson, holdFirmgeojson){
    map.addSource('locations', {
      'type': 'geojson',
      'data': geojson
    });

    map.addSource('instructLocation', {
      'type': 'geojson',
      'data': instructiongeojson
    });

    map.addSource('selectedInstruction', {
      'type': 'geojson',
      'data': selectedjobgeojson
    });

    map.addSource('holdFirmLocations', {
      'type':     'geojson',
      'data':     holdFirmgeojson
    });
  }

  officeMapFilter(data){
    let postcodeinput = document.getElementsByClassName('office-map-panel');
    if (data.is_office == true || data.hold_sus == true) {
      return $(postcodeinput).removeClass('d-none').addClass('d-block');
    } else {
      return $(postcodeinput).removeClass('d-block').addClass('d-none');
    }
  }
}
