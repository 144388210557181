export const CustomEditor = {
  lastCaretPosition: 0,
  clickedButton: {
    bold: false,
    italic: false,
    underline: false,
    insertUnorderedList: false
  },
  formattingButtons: {
    bold: 'B',
    italic: 'I',
    underline: 'U',
    insertUnorderedList: 'UL'
  },

  applyFormatting(event) {
    let command = event.currentTarget.dataset.command
    let toolbarButtons = this.getToolbarButtons(event.currentTarget.parentElement)
    event.target.classList.toggle('active')
    toolbarButtons.forEach(button => {
      let cmd = button.dataset.command
      this.clickedButton[cmd] = button.classList.contains('active')
    })
    this.setCaretPosition(command)
  },

  toggleCommands() {
    for (let cmd in this.clickedButton) {
      let cmdActive = document.queryCommandState(cmd)
      if (this.clickedButton[cmd] && cmdActive) continue

      this.toggleOnConditions(cmd, cmdActive)
    }
  },

  toggleOnConditions(command, isActive) {
    if ((this.clickedButton[command] && !isActive) || (!this.clickedButton[command] && isActive))
      document.execCommand(command, false)
  },

  setCaretPosition(command) {
    let sel = window.getSelection()
    if (this.lastCaretPosition === 0) {
      let editorId = event.target.parentElement.dataset.id
      let editorContainer = document.getElementById(editorId)
      editorContainer.focus()
      sel.selectAllChildren(editorContainer)
      sel.collapseToEnd()
    }
    else {
      sel.removeAllRanges()
      sel.addRange(this.lastCaretPosition)
    }
    this.toggleCommands()
  },

  handleFormattingChange(event) {
    // event.target will be text node if any and event.currentTarget will be contenteditable div on focus
    let editorContainer = event.currentTarget
    let buttonRow = this.getButtonRow(editorContainer)
    let toolbarButtons = this.getToolbarButtons(buttonRow)
    if (event.ctrlKey) {
      this.formattingByShortcut()
      this.toggleButtonAndCommand(toolbarButtons)
    }

    let currentSelection = window.getSelection()
    this.lastCaretPosition = currentSelection.getRangeAt(0)
    if (event.key === 'Enter') {
      this.toggleButtonAndCommand(toolbarButtons)
    } else {
      this.updateButtonStates(currentSelection, buttonRow)
    }
  },

  formattingByShortcut() {
    if (event.keyCode === 76 && event.shiftKey) { // Ctrl + Shift + L
      this.clickedButton['insertUnorderedList'] = !this.clickedButton['insertUnorderedList']
    } else if (event.keyCode === 66) { // Ctrl + b
      this.clickedButton['bold'] = !this.clickedButton['bold']
    } else if (event.keyCode === 73) { // Ctrl + i
      this.clickedButton['italic'] = !this.clickedButton['italic']
    } else if (event.keyCode === 85) { // Ctrl + u
      this.clickedButton['underline'] = !this.clickedButton['underline']
    }
  },

  toggleButtonAndCommand(toolbarButtons) {
    toolbarButtons.forEach(button => {
      let command = button.dataset.command
      button.classList.toggle('active', this.clickedButton[command])
    })
    this.toggleCommands()
  },

  getButtonRow(editorContainer) {
    let editorId = editorContainer.id
    return document.querySelector(`[data-id="${editorId}"]`)
  },

  getToolbarButtons(buttonRow) {
    return Array.from(buttonRow.querySelectorAll('.button-row div'))
  },

  updateButtonStates(selection, buttonRow) {
    let tagNames = this.getTagNamesInSelection(selection)

    for (let formattingButton in this.formattingButtons) {
      let button = buttonRow.querySelector(`[data-command="${formattingButton}"]`)
      let isActive = tagNames.includes(this.formattingButtons[formattingButton])
      this.clickedButton[formattingButton] = isActive
      this.toggleButtonState(button, isActive, formattingButton)
    }
  },

  toggleButtonState(button, isActive, command) {
    if (this.clickedButton[command] && document.queryCommandState(command)) {
      button.classList.toggle('active', true)
      return
    }

    button.classList.toggle('active', isActive)
  },

  getTagNamesInSelection(selection) {
    let currentNode = selection.anchorNode
    let tagNames = []

    while (currentNode && currentNode.nodeName !== 'DIV') {
      if (currentNode.nodeType === Node.ELEMENT_NODE) {
        let tagName = currentNode.tagName.toUpperCase()
        tagNames.push(tagName)
        tagNames = this.getTagsfromStyle(tagNames, currentNode)
      }
      currentNode = currentNode.parentNode
    }
    return [...new Set(tagNames)]
  },

  // consider the following HTML: <i style="font-weight: bold;">
  // Till now we only got the italic tag as formatting tag in tagNames, but the HTML string also has bold formatting with style attribute.
  // Now we will identify and extract the formatting tags from the style attribute.
  getTagsfromStyle(tagNames, currentNode) {
    let styleAttribute = currentNode.getAttribute('style')
    if (styleAttribute) {
      let parseStyle = styleAttribute.split(';').reduce(this.parseStyleDeclaration, {})
      let values = Object.values(parseStyle)
      values.forEach(value => { tagNames.push(this.formattingButtons[value]) })
    }
    return tagNames
  },

  parseStyleDeclaration(style, declaration) {
    declaration = declaration.trim().split(':')
    if (declaration[0]) 
      (style[declaration[0].trim()] = declaration[1].trim())

    return style
  },

  resetConstAndButton() {
    let buttonRow = this.getButtonRow(event.target)
    let toolbarButtons = this.getToolbarButtons(buttonRow)
    if (toolbarButtons.includes(event.relatedTarget)) return

    this.lastCaretPosition = 0
    this.clickedButton = {
      bold: false,
      italic: false,
      underline: false,
      insertUnorderedList: false

    }
    toolbarButtons.forEach(button => {
      button.classList.remove('active')
    })
  }
}
