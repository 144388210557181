import DragDropController from './drag_drop_controller'

export default class extends DragDropController {
  static targets = ['bookmarkList', 'surveyType', 'account', 'bookmarkId' ]

  connect() {
    this.scrollCenter()
    this.getbookmarkStatus()
  }

  scrollCenter() {
    let id = window.location.href.split('field_id-')[1]
    if (id) {
      let field = document.getElementById('field-' + id)
      field.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }
  }

  dragstart(event) {
    let checkedValue = document.querySelectorAll(`input[type='checkbox']:checked`)
    let transferId = {}
    transferId['id'] = new Array()
    if (checkedValue.length === 0) {
      this.getDraggedField(event, event.target, transferId)
      this.setDragKey(event, transferId)
    } else {
      for (let dragElement of checkedValue) {
        let element = dragElement.parentElement
        this.getDraggedField(event, element, transferId)
      }
      this.setDragKey(event, transferId)
    }
  }

  getDraggedField(event, element, transferId) {
    if(event.dataTransfer.getData('application/drag-key') == '' && element.hasAttribute('data-order')) {
      transferId['id'].push(element.getAttribute('data-field-id'))
    }
  }

  setDragKey(event, transferId) {
    let dragKey = ''
    if (transferId['id'].length != 0) {
      dragKey = JSON.stringify(transferId)
      event.dataTransfer.setData('application/drag-key', dragKey)
      event.dataTransfer.effectAllowed = 'move'
    }
  }

  applyFocus(event) {
    event.target.style.border = '4px dotted #696969'
    event.preventDefault()
    return true
  }

  resetFocus(event) {
    event.target.style.border = ''
  }

  navigateDrop(event) {
    let targetId = event.target.getAttribute('data-target-id')
    let targetElement  = document.getElementById(targetId)
    let eventTarget = targetElement?.querySelector('li:last-child') || targetElement
    let fieldTargetId = eventTarget?.getAttribute('data-field-id')
    let targetField = eventTarget?.classList?.contains('ul-element')

    this.checkForDrop(event, (targetField || !fieldTargetId), eventTarget, this.bookmarkListTarget)
    let element = document.getElementsByClassName('list-group-item active')[0]
    if (element) {
      element.className = 'pointer-cursor list-group-item'
    }
    event.target.className = 'pointer-cursor list-group-item active'
    this.resetFocus(event)
  }

  checkForDrop(event, elementPresent, eventTarget, formElement) {
    if(elementPresent) {
      this.createNewListItem(event, eventTarget, formElement)
    } else {
      let targetElement = this.targetElement(eventTarget)
      this.dropItems(targetElement, formElement)
    }
  }

  drop(event) {
    let eventTarget = event.target
    let targetField = eventTarget.classList.contains('ul-element')
    let element = eventTarget.parentElement.classList.contains('ul-element')
    this.checkForDrop(event, (targetField || element), eventTarget)
  }

  dropItems(targetElement, dragField = this.element) {
    if(targetElement.hasAttribute('data-field-id')) {
      let dropTarget = targetElement
      let endPoint = (dragField == this.element) ? 0 : 1
      let droppedOrder = parseInt(targetElement.getAttribute('data-order')) + endPoint
      let draggedIDs = event.dataTransfer.getData('application/drag-key')

      if (draggedIDs === '')
        return alert('No fields were select. Please select fields before moving them.')

      let draggedId = JSON.parse(draggedIDs)
      let fieldContent = []

      for(let id of draggedId['id']) {
        let draggedItem = dragField.querySelector(`[data-field-id='${id}']`)
        this.checkDropCondition(dropTarget, draggedItem)
        let titleID = targetElement.closest('ul.ul-element').dataset['id']
        this.insertTargetElement(dropTarget, draggedItem, targetElement, event)
        this.setContentField(fieldContent, droppedOrder, id, titleID)
        droppedOrder += 1
        dropTarget = draggedItem
      }
      this.updateData({fields: fieldContent})
    }
  }

  checkDropCondition(dropTarget, draggedItem) {
    if (dropTarget == undefined || draggedItem == undefined) { return }
    let dropTargetValue = dropTarget.lastElementChild.value
    let draggedTargetValue = draggedItem.lastElementChild.value
    if (dropTargetValue == undefined || draggedTargetValue == undefined) { return }
    if (dropTarget != null && draggedItem != null && dropTargetValue != draggedTargetValue) {
      draggedItem.lastElementChild.value = dropTargetValue
    }
  }

  targetElement(eventTarget) {
    if (!eventTarget.hasAttribute('data-field-id')) {
      return eventTarget.offsetParent
    }
    return eventTarget
  }

  createNewListItem(event, eventTarget, dragField = this.element) {
    let parentElement = eventTarget.closest('ul.ul-element')
    parentElement.innerHTML = ''
    parentElement.className = 'list-group'
    let droppedOrder = 1
    let draggedIDs = event.dataTransfer.getData('application/drag-key')

    if (draggedIDs === '')
      return alert('No fields were select. Please select fields before moving them.')

    let draggedId = JSON.parse(draggedIDs)
    let fieldContent = []

    for(let id of draggedId['id']) {
      let newListItem = dragField.querySelector(`[data-field-id='${id}']`)
      if (newListItem != null) {
        parentElement.appendChild(newListItem)
        let titleName = parentElement.dataset['title']
        newListItem.lastElementChild.previousElementSibling.value = titleName
        let titleID = parentElement.dataset['id']
        newListItem.lastElementChild.value = titleID
        this.setContentField(fieldContent, droppedOrder, id, titleID)
        droppedOrder += 1
        parentElement = newListItem.parentElement
      }
    }
    this.updateData({fields: fieldContent})
  }

  setContentField(fieldContent, droppedOrder, id, titleID) {
    let content = { field: { dropped_position: droppedOrder, dragged_id: id, bookmark_id: titleID } }
    fieldContent.push(content)
  }

  updateData(fieldContent) {
    let account = this.accountTarget.value
    let surveyType = this.surveyTypeTarget.value
    let updateUrl = `/accounts/${account}/survey_types/${surveyType}/update_order`
    this.updateFetchedData(fieldContent, updateUrl)
  }

  toggleAll() {
    let bookmarks = document.getElementsByClassName('bookmark-collapse')
    Array.from(bookmarks).forEach((element) => {
      let bookmark = element
      if (event.target.getAttribute('data-collapse') == 'true') {
        this.collapseBookmark(bookmark)
      }
      else {
        this.expandBookmark(bookmark)
      }
    })
  }

  toggleCheckbox() {
    let section = document.getElementById(`bookmark-list-${event.target.dataset['id']}`)
    let checkboxes = section.querySelectorAll(`input[type='checkbox']`)

    for (let checkbox of checkboxes) {
      checkbox.checked = !!event.target.checked
    }
  }

  bookmarkCollapseStatus() {
    let bookmarks = document.getElementsByClassName('close-bookmark')
    let ids = []
    for (let bookmark of bookmarks) {
      ids.push(bookmark.id)
    }
    window.sessionStorage.setItem('bookmarks', JSON.stringify(ids))
  }

  getbookmarkStatus() {
    let storedBookmarks = JSON.parse(sessionStorage.getItem('bookmarks'))
    if(storedBookmarks != null) {
      for (let i = 0; i < storedBookmarks.length; i++) {
        let bookmark = document.getElementById(storedBookmarks[i])
        this.collapseBookmark(bookmark)
      }
    }
    if (event) {
      sessionStorage.removeItem('bookmarks')
    }
  }

  bookmarkToggle() {
    let id = event.target.parentNode.id.replace('_', '-')
    let bookmark = document.getElementById(id)
    if (bookmark.classList.contains('show')) {
      this.collapseBookmark(bookmark)
    } else if (bookmark.classList.contains('close-bookmark')) {
      this.expandBookmark(bookmark)
    }
  }
}
