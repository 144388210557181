import { Controller } from "stimulus"
import InfoboxController from './infobox_controller'
import PanelFeatureController from './panel_feature_controller'

export default class LoadMapController extends Controller {
  showMapView(lat, long, zoomSize = 7) {
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [lat, long],
      zoom: zoomSize
    })
    this.map.addControl(new mapboxgl.NavigationControl())
    this.map.on('load', () =>  { this.onMapLoaded() })
  }

  getGeojsonData(locations) {
    const geojsonData = {
      'type': 'FeatureCollection',
      'features': locations
    }
    this.map.addSource('locations', {
      'type': 'geojson',
      'data': geojsonData
    })
    this.addMarkerOnMap(geojsonData)
  }

  initializeVariable(){
    this.infobox = new InfoboxController()
    this.infobox.connect()
    this.panelFeature = new PanelFeatureController()
  }
}
