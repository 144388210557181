import { Controller } from "stimulus"
import axios from 'axios'
import { clearInputFields } from '../lib/helper_functions'

export default class PrivateClientController extends Controller {

  static targets = [
    "showList", "showApplicantForm"
  ]

  clearPrivateClientDetail(container){
    clearInputFields(container)
    let sel = container.getElementsByTagName('select')
    var selectFields = Array.prototype.slice.call(sel)
    selectFields.forEach((selectfield ) => {
      selectfield.value = ''
    })
  }

  toggleDisplay(show, hide){
    let showListClasses = this.showListTarget.classList
    showListClasses.add(show)
    showListClasses.remove(hide)
    this.toggleApplicantForm(show, hide)
  }

  toggleApplicantForm(show, hide){
    let applicantFormClasses = this.showApplicantFormTarget.classList
    applicantFormClasses.add(hide)
    applicantFormClasses.remove(show)
  }
}
