import { Controller } from "stimulus"
import introJs from 'intro.js'

export default class extends Controller {
  static targets = ["tour"]

  static values = { showTour: Boolean }

  connect(){
    if(this.showTour()){
      $('#tourModal').modal('show')
    }
  }

  getCurrentUserId(){
    return this.tourTarget.dataset.userId
  }

  startTour(){
    $('#tourModal').modal('hide')
    introJs().start()
    this.doneTour()
  }

  doneTour(){
    localStorage.setItem('doneTour', this.getCurrentUserId())
  }

  showTour() {
    return localStorage.getItem('doneTour') != this.getCurrentUserId() || this.showTourValue
  }
}
