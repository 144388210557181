// Use mapbox to show where the property is located
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "map-container" ]

  connect(){
    const latitude = this.element.getAttribute("data-latitude");
    const longitude = this.element.getAttribute("data-longitude");
    this.shopwMap(latitude, longitude);
  }

  shopwMap(latitude, longitude){
    const map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v9', //stylesheet location
      center: [longitude, latitude], // starting position
      zoom: 12 // starting zoom
    });

    map.addControl(new mapboxgl.NavigationControl());

    let marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(map);
  }
}
