import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    this.moveFocusToField(this.getCurrentField())
  }

  moveFocusToField(field) {
    const container = document.querySelector('#' + field)
    container?.querySelector('.form-group .field')?.focus()
  }

  getCurrentField() {
    const defaultAnchor = window.location.hash.substr(1)
    const defaultField = this.element.getAttribute('data-default-field')
    if (defaultAnchor) {
      return defaultAnchor
    } else {
      return defaultField
    }
  }
}
