import { Controller } from "stimulus"
import axios from 'axios'
import LoadMapController from './load_map_controller'

export default class extends LoadMapController {
  static targets = ['mapView', 'postcode']
  static values = { locations: Array }

  connect() {
    if(window.location.href.includes("map")){
      this.showMapView(-0.999, 53.000)
    }
    this.initializeVariable()
  }

  get mapView(){
    return this.mapViewTarget.dataset
  }

  postcodeMarker(){
    const el = document.createElement('div')
    el.className = 'selected-job-marker'
    let postcode = JSON.parse(this.mapView.postcode)
    if (postcode) {
      let postcodeCoordinates = [parseFloat(postcode["geometry"]["coordinates"][0]), parseFloat(postcode["geometry"]["coordinates"][1])]
      let mark = new (mapboxgl.Marker)(el, { offset: [
              this.infobox.markerWidth,
              this.infobox.markerHeight
            ]
          }).setLngLat(postcodeCoordinates).addTo(this.map)
      this.flyToPin(postcodeCoordinates)
      let exposure = JSON.parse(this.mapView.exposure)
      if(exposure) {
        this.infobox.createRadiusArea(this.map)
        this.map.getSource('polygon').setData(this.infobox.createGeoJSONCircle(postcodeCoordinates, exposure).data)
      }
    }
  }

  addMarkerOnMap(instructiongeojson) {
    instructiongeojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = 'instruct-marker'
      // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      this.panelFeature.createMarker(this.map, el, marker)
      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, marker, this.map)
      })

      el.addEventListener('click', (e) => {
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
        let isLegacyData = marker['properties']['legacy_data'] == true
        location.href = this.summaryUrl(isLegacyData, marker)
      }, false)
    })

    this.postcodeMarker()
  }

  onMapLoaded() {
    this.getGeojsonData(this.locationsValue)
    this.map.addSource("polygon", this.infobox.createGeoJSONCircle([-0.999, 53.000], 40))
  }

  summaryUrl(isLegacyData, marker){
    let instruction = isLegacyData ? 'legacy_instructions' : 'instructions'
    let account = isLegacyData ? marker['properties']['client_id'] : marker['properties']['account_id']
    return `/accounts/${account}/${instruction}/${marker['properties']['id']}`
  }
}
