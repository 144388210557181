import  { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["instType"]
  connect(){
    let pastDueElement = document.getElementById('secondary-past_due');
    let insType = this.instTypeTarget.textContent.replace(/\sType:/g,'').trim();
    if(pastDueElement != undefined){
      if (["Residential", "Commercial", "Development"].includes(insType)){
        pastDueElement.style.display = 'block';
      }
      if (insType == "All"){
        pastDueElement.style.display = 'none';
      }
    }
  }
}
