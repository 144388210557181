import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "billingEmail", "billingContact",
                     "clientEmail", "clientContact", "clientTitle" ]

  copyBillingDetails(){
    this.billingEmailTarget.value = this.clientEmailTarget.value;
    this.billingContactTarget.value = this.clientTitleTarget.value + " " + this.clientContactTarget.value;
  }
}
