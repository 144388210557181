import { Controller } from "stimulus"
import LoadMapController from './load_map_controller'

export default class extends LoadMapController {
  static targets = ['mapView', 'mapViewType']
  static values = { locations: Array }

  connect() {
    
    let viewType = this.mapViewTypeTarget.dataset.viewtype
    if(viewType == "map"){
      this.showMapView(-0.999, 53.000)
    }
    this.initializeVariable()
  }

  get mapView(){
    return this.mapViewTarget.dataset
  }

  addMarkerOnMap(geojson) {
    geojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = 'instruct-marker'
      this.panelFeature.createMarker(this.map, el, marker)
      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, marker, this.map)
      })

      el.addEventListener('click', (e) => {
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
        this.show_avm_history(marker)
      })
    })
  }

  show_avm_history(currentFeature) {
    if (this.mapViewTarget.dataset.isAdmin != "true"){
      window.location =  `/accounts/${currentFeature.properties.account_id}/avm/${currentFeature.properties.id}`
    }
    else{
      window.location = `/admins/avm/${currentFeature.properties.id}`
    }
  }

  onMapLoaded() {
    this.getGeojsonData(this.locationsValue)
  }
}
