import { Controller } from "stimulus"
import { getMetaValue, requestHeaders } from '../lib/helper_functions'

export default class extends Controller {
  static values = { accountApiTokenId: Number, accountId: Number }

  toggleStatus(event) {
    const headers = {
      ...requestHeaders,
      'X-CSRF-Token': getMetaValue('csrf-token')
    }
    fetch(`/admins/accounts/${this.accountIdValue}/account_api_tokens/${this.accountApiTokenIdValue}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
            status: event.target.checked ? 'active' : 'inactive'
        })
    })
    .then(response => {
      return response.json()
    })
  }
}