import { toggleClasses } from '../../lib/helper_functions'

export const EventCalendarHelper = {
  addChildElement(parentElement, classes = '') {
    const node = document.createElement('div')
    node.className = classes
    parentElement.appendChild(node)
    return node
  },

  customEventPopup(info, eventDetails, hideEditEventButton) {
    let customEventPopupData = `<div class="d-flex flex-row h6">
                              <div class="p-1"><i class="fa fa-list mr-1"></i></div>
                              <div class="p-1">${info.event.title}</div>
                            </div>
                            <div class="d-flex flex-row h6">
                              <div class="p-1"><i class="fa fa-clock mr-1"></i></div>
                              <div class="p-1">${info.timeText}</div>
                            </div>
                            <div class="d-flex flex-row h6">
                              <div class="p-1"><i class="fa fa-clipboard-list mr-1"></i></div>
                              <div class="p-1">${eventDetails.description}</div>
                            </div>`
    if (!hideEditEventButton) {
      customEventPopupData += `<div class="d-flex flex-row h6 float-right">
                            <a data-toggle="modal" data-target="customEvent" class="btn btn-primary btn-sm p-1" type="button" href="/accounts/${eventDetails.account_id}/custom_events/${eventDetails.custom_event_id}/edit" data-remote="true" data-value="editCustomEvent">Edit Event</a>
                          </div>`
    }
    return customEventPopupData
  },

  instructionEventPopup(info, eventDetails) {
    return `<div class="d-flex flex-row h6">
              <div class="p-1"><i class="fa fa-list mr-1"></i></div>
              <div class="p-1"><a target="_blank" href="/accounts/${eventDetails.surveying_firm_id}/instructions/${info.event.id}">${eventDetails.full_address}</a></div>
            </div>
            <div class="d-flex flex-row h6">
              <div class="p-1"><i class="fa fa-clock mr-1"></i></div>
              <div class="p-1">${info.timeText}</div>
            </div>
            <div class="d-flex flex-row h6">
              <div class="p-1"><i class="fa fa-clipboard-list mr-1"></i></div>
              <div class="p-1">${eventDetails.survey_type_name}</div>
            </div>`
  },

  validateForm(formElement) {
    let isValid = true
    let requiredFields = formElement.querySelectorAll('textarea:required, input:required')

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        isValid = false
      }
    })

    return isValid
  },

  customEventError(error) {
    if (error instanceof Response) {
      error.json().then(errorData => {
        // Process the errorData containing error messages
        let parentElement = document.getElementById('custom-event-error-list')
        parentElement.innerHTML = ''
        errorData.messages.split(',').forEach(message => {
          let errorItem = document.createElement('li')
          errorItem.innerHTML = message
          parentElement.appendChild(errorItem)
        })
        let errorDiv = document.getElementById('custom-event-error-div')
        toggleClasses(errorDiv, 'd-none', 'd-block')
      }).catch(parseError => {
        console.error('Failed to parse error response:', parseError)
      })
    }
  }
}
