import { Controller } from "stimulus"
import axios from 'axios'
import { DataTable } from 'simple-datatables'
import { getMetaValue, requestHeaders, toggleClasses } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ["valuationType", "editable", "sageReferenceBlock"]

  connect() {
    $(document).ready(() => {
      this.accountDataTable()
    })
  }

  accountDataTable() {
    let dataTable = new DataTable("#accountData", {
      columns: [
        { select: 2, sortable: false }
      ]
    })
  }

  updateSageReference(event) {
    let recordId = event.target.dataset.recordId
    let sageRef = document.getElementById(recordId).value
    let listParams = {
      params: {
        sage_reference: sageRef,
        record_id: recordId.split('-').slice(-1)[0],
        valuation_type: this.valuationTypeTarget.value
      },
      headers: requestHeaders
    }
    let url = `/admins/stats/update_sage_reference`
    axios.get(url, listParams)
    this.toggleSageBlocks(recordId, sageRef)
  }

  toggleSageBlocks(recordId, sageRef) {
    let sageTextBlock = document.getElementById(`sage-text-${recordId}`)
    let sageInputBlock = document.getElementById(`sage-input-${recordId}`)
    let sageRefElement = sageTextBlock.querySelector('p')
    sageRefElement.innerText = sageRef
    toggleClasses(sageTextBlock, 'd-none', 'd-block')
    toggleClasses(sageInputBlock, 'd-block', 'd-none')
  }

  toggleSageReferenceField(event) {
    let sageRecordId = event.target.dataset.sageRecordId
    let sageTextBlock = document.getElementById(`sage-text-${sageRecordId}`)
    let sageInputBlock = document.getElementById(`sage-input-${sageRecordId}`)
    toggleClasses(sageTextBlock, 'd-block', 'd-none')
    toggleClasses(sageInputBlock, 'd-none', 'd-block')
  }

  updateSupplierInvoice(event) {
    let recordId = event.currentTarget.dataset.id
    let value = event.currentTarget.dataset.value
    let params = {
      record_id: recordId.split('-').slice(-1)[0],
      supplier_invoiced: value
    }
    let url = `/admins/stats/update_supplier_invoiced`
    let headers = requestHeaders
    headers['X-CSRF-Token'] = getMetaValue('csrf-token')

    fetch(url, {
      headers: headers,
      method: 'PATCH',
      body: JSON.stringify(params)
    })
    this.toggleSupplierInvoiceBlocks(recordId, value)
  }

  toggleSupplierInvoiceBlocks(recordId, value) {
    let invoiceTextBlock = document.getElementById(`invoice-text-${recordId}`)
    let invoiceInputBlock = document.getElementById(`invoice-input-${recordId}`)
    if (value === 'true') {
      invoiceTextBlock.querySelector('p').innerText = 'Yes'
      toggleClasses(invoiceTextBlock, 'd-none', 'd-flex')
      toggleClasses(invoiceInputBlock, 'd-block', 'd-none')
    } else {
      invoiceInputBlock.querySelector('input').checked = false
      toggleClasses(invoiceInputBlock, 'd-none', 'd-block')
      toggleClasses(invoiceTextBlock, 'd-flex', 'd-none')
    }
  }
}
