import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ["referralPartner"]

  deleteReferralPartner(e){
    let url = e.target.dataset.url
    let params = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') } }

    axios.delete(url, params)
    .then((response)=> {
      let responseData = response.data
      const categoryData = this.referralPartnerTarget

      categoryData.querySelector(`#referral-partner-${response.data}`).remove()
      categoryData.querySelector(`#action-${response.data}`).remove()
    })
  }
}
