import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [ "getListType"]
  
  get url(){
    return this.getListTypeTarget.dataset.url;
  }

  choose_surveyors_list(){
    let surveyorListType = this.getListTypeTarget.value;
    let surveyorParams = {
      params:  {list_type: surveyorListType},
      headers: {'Content-Type':'application/json','Accept':'application/json'} 
    }
    axios.get(this.url, surveyorParams)
      .then((response)=> {
        let surveyorData = response.data;
        const surveyorElement = document.getElementById('select_surveyor');
        let updateButton = document.getElementById('surveyor-update');
        surveyorElement.innerHTML = "";
        if(surveyorData.length == 0 ){
          updateButton.disabled = true;
        }
        if(surveyorData.length > 0){
          updateButton.disabled = false;
          surveyorData.forEach((value, index) => {
            let options = surveyorElement.appendChild(document.createElement('option'));
            options.value = value.id;
            options.innerHTML = value.name;
          });
        }
      }) 
  }
}