import Trix from 'trix'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['colorButton', 'colorMenu', 'customColorPicker']

  // Configure custom Trix text attributes (underline and color) on connect
  connect() {
    Trix.config.textAttributes.underline = {
      tagName: 'u',
      style: { textDecoration: 'underline' },
      inheritable: true
    }

    Trix.config.textAttributes.color = {
      styleProperty: 'color',
      inheritable: true,
      parser: function (element) {
        return element.style.color
      }
    }

    this.initializeTrixColorPicker()
  }

  // Set up custom color picker and dropdown menu for Trix editor
  initializeTrixColorPicker() {
    document.addEventListener('trix-initialize', (event) => {
      const toolbar = event.target.toolbarElement.querySelector('.trix-button-group--text-tools')
      if (!toolbar || toolbar.querySelector('.trix-color-dropdown')) return
      this.addCustomOptionsOne()
      this.addCustomOptions(toolbar)
      this.setupColorPickerEventListeners(event, toolbar)
    })
  }

  // Add color picker button and dropdown menu to the toolbar
  addCustomOptions(toolbar) {
    const colorPickerHTML = `
      <button type='button' data-trix-target='colorButton' class='trix-button' title='Text Color'>A
        <span class='color-underline'></span>
      </button>
      <div class='trix-color-dropdown'>
        <div data-trix-target='colorMenu' class='color-dropdown-menu' style='display: none;'>
          ${this.createColorSection('Theme Colors', ['#000000', '#808080', '#D3D3D3'])}
          ${this.createColorSection('Standard Colors', ['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF', '#800080'])}
          <div class='color-section'>
            <strong>Custom Color</strong>
            <input type='color' data-trix-target='customColorPicker'>
          </div>
        </div>
      </div>
    `
    toolbar.insertAdjacentHTML('beforeend', colorPickerHTML)
  }

  // Create sections with color swatches for dropdown menu
  createColorSection(title, colors) {
    return `
      <div class='color-section'>
        <strong>${title}</strong>
        <div class='color-row'>
          ${colors.map(color => `<span class='color-swatch' style='background-color: ${color};' data-color='${color}'></span>`).join('')}
        </div>
      </div>
    `
  }

  // Set up event listeners for the color picker dropdown and custom color picker
  setupColorPickerEventListeners(event, toolbar) {
    const colorButton = toolbar.querySelector('[data-trix-target="colorButton"]')
    const colorMenu = toolbar.querySelector('[data-trix-target="colorMenu"]')
    const colorPicker = toolbar.querySelector('[data-trix-target="customColorPicker"]')

    if (!colorButton || !colorMenu || !colorPicker) return

    this.initializeColorButton(colorButton, colorMenu)
    this.initializeSwatchClickHandlers(event, colorMenu, colorButton)
    this.initializeCustomColorPicker(event, colorPicker, colorButton)
    this.initializeOutsideClickHandler(colorMenu)
  }

  // Toggle dropdown menu visibility on button click
  initializeColorButton(button, menu) {
    button.addEventListener('click', (event) => {
      menu.style.display = menu.style.display === 'none' ? 'block' : 'none'
      event.stopPropagation()
    })
  }

  // Apply selected swatch color to text and update button color
  initializeSwatchClickHandlers(event, menu, button) {
    menu.querySelectorAll('.color-swatch').forEach(swatch => {
      swatch.addEventListener('click', (e) => {
        const color = e.target.getAttribute('data-color')
        this.applyColor(event.target.editor, color)
        this.updateButtonColor(button, color)
        menu.style.display = 'none'
      })
    })
  }

  // Apply custom color from color input to text and update button color
  initializeCustomColorPicker(event, colorPicker, button) {
    colorPicker.addEventListener('input', (e) => {
      const color = e.target.value
      this.applyColor(event.target.editor, color)
      this.updateButtonColor(button, color)
    })
  }

  // Close dropdown when clicking outside
  initializeOutsideClickHandler(menu) {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.trix-color-dropdown')) {
        menu.style.display = 'none'
      }
    })
  }

  // Apply color to selected text or deactivate the color attribute
  applyColor(editor, color) {
    const selectedRange = editor.getSelectedRange()
    const [start, end] = selectedRange

    if (start !== end) {
      const selectedText = editor.getDocument().toString().slice(start, end)
      const coloredText = `<span style='color: ${color};'>${selectedText}</span>`
      editor.setSelectedRange(selectedRange)
      editor.insertHTML(coloredText)
    } else {
      editor.deactivateAttribute('color')
      editor.activateAttribute('color', color)
    }
  }

  // Update color button style to match selected color
  updateButtonColor(button, color) {
    button.style.color = color
    const underline = button.querySelector('.color-underline')
    if (underline) {
      underline.style.backgroundColor = color
    }
  }

  // Add custom underline button to the toolbar
  addCustomOptionsOne() {
    const toolbar = document.querySelector('.trix-button-group--text-tools')
    if (!toolbar) return setTimeout(() => this.addCustomOptionsOne(), 100)
    const underlineButton = this.createButton('U', 'underline', 'u', 'Underline')
    underlineButton.style.fontSize = '15px'
    toolbar.appendChild(underlineButton)
  }

  // Create a toolbar button with specified attributes
  createButton(innerHTML, attribute, key, title) {
    const button = document.createElement('button')
    Object.assign(button, { type: 'button', innerHTML, title, tabIndex: -1 })
    button.classList.add('trix-button', `trix-button--icon-${attribute}`)
    button.dataset.trixAttribute = attribute
    button.dataset.trixKey = key
    return button
  }
}
