import { Controller } from 'stimulus'
import { applyMixins } from '../lib/helper_functions'
import { pasteTextWithFormat, replaceCarriageWithBrTag } from '../lib/rich_text_helper_functions'
import { CustomEditor } from './mixins/custom_editor'

export default class extends Controller {
  static targets = ['contentEditor', 'hiddenContentInput', 'subCategory']

  connect() {
    applyMixins(this, [CustomEditor])

    if (this.hasContentEditorTarget) {
      this.contentEditorTarget.innerHTML = replaceCarriageWithBrTag(this.hiddenContentInputTarget.value)
    }
  }

  updateHiddenInputValue() {
    this.hiddenContentInputTarget.value =  event.target.innerHTML
  }

  pasteTextInField(e) {
    pasteTextWithFormat(e)
    let element = e.currentTarget
    let text = element.innerHTML
    element.innerHTML = replaceCarriageWithBrTag(text)
  }

  modifySubCategory() {
    let element = event.target
    let url = element.options[element.selectedIndex].dataset.url
    this.subCategoryTarget.innerHTML = ''
    fetch(url)
    .then(response => response.json())
    .then((data) => {
      data.groups.forEach(subcategory => {
        this.addOption(this.subCategoryTarget, subcategory.id, subcategory.title)
      })
    })
  }

  addOption(subCategoryElement, value, text) {
    let option = document.createElement('option')
    option.value = value
    option.text = text
    subCategoryElement.add(option)
  }
}
