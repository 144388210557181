import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['planCharge', 'planName', 'displayData', 'stripeUrlBox', 'setUpFee',
                    'displayPlanData', 'planId', 'submitButton', 'planInstructionCharge',
                    'stripeCustomerButton', 'bannerMessage', 'instructionCharge', 'accountPlanCharge',
                    'copyIcon']

  static values = { account: Object, planData: Array, activeName: String,
                    oneOffFee: Number, privateSurvey: Boolean, defaultOneOffFee: Number,
                    adminInterface: Boolean }

  connect() {
    this.getAccountPlan()
  }

  get selectedPlan() {
    return this.planNameTarget.selectedOptions[0]
  }

  getAccountPlan() {
    this.planIdTarget.value = this.selectedPlan.value

    this.managePlanDetails()
    if (this.selectedPlan.value !== '' ) {
      this.fetchSessionUrl()
    }
  }

  managePlanDetails() {
    const planName = this.selectedPlan.text
    const planCharges = Object.fromEntries(this.planDataValue)
  
    switch (planName) {
      case 'Student':
        this.handleStudentPlan(planCharges[planName])
        break
      case 'Essentials':
      case 'Pro':
        this.handleEssentialsOrProPlan(planCharges[planName])
        break
      case 'Pay as you go':
        this.handlePayAsYouGoPlan(planCharges[planName], planName)
        break
      default:
        this.getDefaultData()
    }

    this.setAccountPlanCharge()
  }

  setAccountPlanCharge() {
    if (this.adminInterfaceValue) {
      this.accountPlanChargeTarget.value = this.hasPlanChargeTarget ?
                                           this.planChargeTarget.value :
                                           this.planInstructionChargeTarget.value
    }
  }

  handleStudentPlan(planCharge) {
    this.setOrRemoveInstructionCharge('add')
    this.setPlanChargeValue(planCharge)
    this.submitButtonTarget.classList.remove('d-none')
    this.handleDisplayPlanData('add')
    this.setUpFeeTarget.value = ''
  }

  handleEssentialsOrProPlan(planCharge) {
    this.setOrRemoveInstructionCharge('add')
    this.setPlanChargeValue(planCharge)
    this.setPlanLink()
    this.setUpFeeTarget.value = ''
  }

  handlePayAsYouGoPlan(planCharge, planName) {
    this.setOrRemoveInstructionCharge('remove')
    this.setPrivateInstructionValue(planCharge)
    this.displayDataTarget.classList.add('d-block')
    this.setPlanLink()
    this.setUpFeeTarget.value = this.activeNameValue === planName ? 
                                this.oneOffFeeValue : this.defaultOneOffFeeValue
  }

  getDefaultData() {
    const privateInstructionCharge = this.accountValue.private_instruction_charge
    const instructionCharge = this.accountValue.instruction_charge
    const privateInstructionChargeValue = privateInstructionCharge || 0

    this.setOrRemoveInstructionCharge('add')
    this.handleDisplayPlanData('add')
    this.submitButtonTarget.classList.add('d-none')

    this.setPlanChargeValue(this.privateSurveyValue ? privateInstructionChargeValue : instructionCharge)
    this.setChargeForClient()
  }

  setPlanLink() {
    this.submitButtonTarget.classList.remove('d-none')
    this.handleDisplayPlanData('remove')
  }

  postRequest(url, requestData) {
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    }).then(response => response.json())
  }

  fetchSessionUrl() {
    const requestData = {
      account_id:         this.accountValue.id,
      plan_id:            this.selectedPlan.value,
      set_up_fee:         this.setUpFeeTarget.value
    }

    this.postRequest('/stripe_sessions', requestData)
    .then(({ session_url }) => {
      if (!session_url) return

      this.stripeUrlBoxTarget.textContent = session_url
      this.copyIconTarget.classList.remove('d-none')
    })
  }

  setPlanChargeValue(value) {
    this.setPrivateInstructionValue(value)
    this.displayDataTarget.classList.remove('d-block')
  }

  copyStripeUrl() {
    navigator.clipboard.writeText(this.stripeUrlBoxTarget.textContent)
  }

  setPrivateInstructionValue(value) {
    if (!this.adminInterfaceValue) return
  
    const chargeValue = this.getChargeValue(value)
    this.updatePlanChargeValue(chargeValue)
  }

  updatePlanChargeValue(chargeValue) {
    try {
      this.planChargeTarget.value = chargeValue
    } catch (error) {
      this.handleChargeTargetError(chargeValue)
    }
  }

  handleChargeTargetError(chargeValue) {
    if (this.hasPlanInstructionChargeTarget) {
      this.planInstructionChargeTarget.value = chargeValue
    }
  }  

  getChargeValue(value) {
    const planChargeValue = this.privateSurveyValue ? this.accountValue.private_instruction_charge : this.accountValue.instruction_charge

    return this.activeNameValue === this.selectedPlan.text 
      ? planChargeValue.toFixed(2) 
      : value.toFixed(2)
  }

  createStripeCustomer(){
    const stripeButton = this.stripeCustomerButtonTarget
    const message = this.bannerMessageTarget
    const requestData = {
      account_id: this.accountValue.id
    }

    message.innerHTML = 'Stripe customer creation is in process'

    this.postRequest('/stripe_sessions/create_stripe_customer', requestData)
    .then(() => { 
      stripeButton.disabled = true
      message.innerHTML = 'Please Refresh the page'
    })
  }

  setOrRemoveInstructionCharge(action) {
    if (this.adminInterfaceValue) {
      const toolType = this.accountValue.tool_type

      if (toolType === 'panel_tool') {
        this.instructionChargeTarget.classList.remove('d-none')
      } else {
        this.instructionChargeTarget.classList.toggle('d-none', action === 'add')
      }
    }
  }

  handleDisplayPlanData(action) {
    if (this.hasDisplayPlanDataTarget) {
      this.displayPlanDataTarget.classList.toggle('d-none', action === 'add')
    }
  }

  setChargeForClient() {
    if (this.accountValue.organization_type == 'client' && this.hasInstructionChargeTarget) {
      this.setOrRemoveInstructionCharge('remove')
    }
  }
}
