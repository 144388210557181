import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    "getFeescale", "getFee", "vat", "surveyingFirmFee",
    "clientFee", "commission", "clientFeeVat", "surveyorVat"
  ]

  connect(){
    if (this.getFeescaleTarget.value != undefined) {
      this.populateDefaultFeeValue();
    }
  }

  get locations(){
    return this.mapDataTarget.dataset.locations;
  }

  get listFeesUrl(){
    return this.getFeescaleTarget.dataset.url;
  }

  get feeUrl(){
    return this.getFeeTarget.dataset.url;
  }

  get vat(){
    return parseFloat(this.vatTarget.value);
  }

  setVatValues(){
    let clientFeeVatElement = this.clientFeeVatTarget;
    let surveyorVatElement = this.surveyorVatTarget;
    let commissionElement = this.commissionTarget;
    clientFeeVatElement.innerHTML = "";
    surveyorVatElement.innerHTML = "";
    commissionElement.innerHTML = "";
  }

  listFeescale(){
    let feeScaleId = this.getFeescaleTarget.value;
    let feeScaleParams = {
      params:  {fee_scale_id: feeScaleId},
      headers: {'Content-Type':'application/json','Accept':'application/json'} 
    }
    this.getFees(feeScaleParams);
  }

  getFees(feeScaleParams){
    const offerSurveyingFirmFee = this.surveyingFirmFeeTarget;
    const offerClientFee = this.clientFeeTarget;
    this.setVatValues();
    axios.get(this.listFeesUrl, feeScaleParams)
      .then((response)=> {
        let feeData = response.data;
        const feeSelect = this.getFeeTarget;
        feeSelect.innerHTML = "";
        if(feeData == null ){
          offerSurveyingFirmFee.value = "";
          offerClientFee.value = "";
        }
        if(feeData.length > 0){
          feeData.forEach((value, index) => {
            let show_fee = "£"+ value.lower + "- £"+ value.upper;
            let options = feeSelect.appendChild(document.createElement('option'));
            options.value = value.id;
            options.innerHTML = show_fee;
          });
        }
        offerSurveyingFirmFee.value = "";
        offerClientFee.value = "";
      })
  }

  populateDefaultFeeValue(){
    let feeScaleId = this.getFeescaleTarget.value;
    let instructionId = this.getFeeTarget.dataset.instruction;
    let feeScaleParams = {
      params:  { fee_scale_id: feeScaleId, instruction_id: instructionId },
      headers: { 'Content-Type':'application/json','Accept':'application/json' } 
    }
    let url = "/admins/surveying_panels/populate_default_fee";
    this.fetchDefaultFeeValue(url, feeScaleParams)
  }

  fetchDefaultFeeValue(url, feeScaleParams){
    axios.get(url, feeScaleParams)
      .then((response)=> {
        let responseData = response.data;
        let feeData = responseData.all_fees;
        let selectedFee = responseData.selected_fee;
        const feeSelect = this.getFeeTarget;
        feeSelect.innerHTML = "";
        let options = feeSelect.appendChild(document.createElement('option'));
        options.value = "";
        options.innerHTML = "";
        if(feeData.length > 0){
          feeData.forEach((value, index) => {
            let show_fee = "£"+ value.lower + "- £"+ value.upper;
            let options = feeSelect.appendChild(document.createElement('option'));
            options.value = value.id;
            options.innerHTML = show_fee;
            if (selectedFee && value.id == selectedFee.id){
              options.selected = 'selected';
              this.populateValues();
            }
          });
        }
      })
  }

  populateValues(){
    let feeId = this.getFeeTarget.value;
    let feeParams = {
      params:  { fee_id: feeId },
      headers: { 'Content-Type':'application/json','Accept':'application/json' } 
    }
    this.calculation(feeParams);
  }

  calculation(feeParams){
    let offerSurveyingFirmFee = this.surveyingFirmFeeTarget;
    let offerClientFee = this.clientFeeTarget ;
    let commissionElement = this.commissionTarget;
    axios.get(this.feeUrl, feeParams)
      .then((response)=> {
        let responseFees = response.data.fees;
        commissionElement.innerHTML = "";
        if (responseFees == null){
          offerSurveyingFirmFee.value = "";
          offerClientFee.value = "";
          this.setVatValues();
          return
        }
        offerClientFee.value = responseFees.customer;
        offerSurveyingFirmFee.value = responseFees.customer - responseFees.commission;
        let clientValue =  parseFloat(offerClientFee.value);
        let surveyorValue = parseFloat(offerSurveyingFirmFee.value);
        let commission = clientValue - surveyorValue;
        this.getVatAmount(clientValue, surveyorValue, commission);
      })
  }

  clientFeeCal(){
    let surveyorValue = parseFloat(this.surveyingFirmFeeTarget.value);
    let clientValue = parseFloat(this.clientFeeTarget.value);
    let commission = clientValue - surveyorValue;
    this.getVatAmount(clientValue, surveyorValue, commission);
  }

  getVatAmount(clientValue, surveyorValue, commission){
    let commissionElement = this.commissionTarget;
    commissionElement.innerHTML = "";
    let clientFeeVatElement = this.clientFeeVatTarget;
    let surveyorVatElement = this.surveyorVatTarget;
    clientFeeVatElement.innerHTML = `£${((clientValue * this.vat) + clientValue).toFixed(2)} [inc VAT]`;
    surveyorVatElement.innerHTML = `£${((surveyorValue * this.vat) + surveyorValue ).toFixed(2)} [inc VAT]`
    let commissionIncVat = ((commission * this.vat) + commission).toFixed(2);
    let text = `Admin Commission: £${commission.toFixed(2)}<span class="text-secondary">( £${commissionIncVat} [inc VAT])</span>`;
    commissionElement.innerHTML = text;
  }
}
