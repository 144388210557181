import { Controller } from "stimulus"
import { DataTable } from 'simple-datatables'

export default class extends Controller {

  connect(){
    $(document).ready(() => {
      this.simpleDataTable();
    });
  }

  simpleDataTable(){
    let dataTable = new DataTable(".simple-datatable");
  }
}