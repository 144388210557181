import { Controller } from "stimulus"
import axios from 'axios'
import { DataTable } from 'simple-datatables'


export default class extends Controller {
  static targets = [ 'constructionType', 'loanChecked', 'loanAmount','rentalChecked',
                     'rentalValue', 'propertyType', 'houseDesc', 'floor',
                     'floorBuilding', 'floorNumber', 'totalFloors','houseSelect',
                     'propStyle', 'propStyleFlat', 'searches', 'viewType']

  connect(){
    if (this.hasSearchesTarget) {
      $(document).ready(() => {
        this.avmDataTable()
      });
    } else {
      this.toggleBlock();
    }
  }

  avmDataTable(){
    let viewType = this.viewTypeTarget.dataset.viewtype;
    if(viewType != "map"){
      new DataTable("#avmData", {
        columns: [
            { select: 2, sortable: false }
        ],
        perPage: 25
      });
    }
  }

  notApplicable(){
    if (this.loanCheckedTarget.checked){
      this.loanAmountTarget.setAttribute("readonly", "readonly")
      this.loanAmountTarget.setAttribute('value', 0.0)
    }else{
      this.loanAmountTarget.setAttribute("value", "")
      this.loanAmountTarget.removeAttribute("readonly")
    }
    if (this.rentalCheckedTarget.checked){
      this.rentalValueTarget.setAttribute('disabled', 'disabled')
    }else{
      this.rentalValueTarget.removeAttribute('disabled')
    }
  }

  toggleBlock(){
    let option = this.propertyTypeTarget.value
    let propStyle = this.propStyleTarget
    let propStyleFlat = this.propStyleFlatTarget
    if(option == 'h' || option == 'b'){
      this.toggleDiv('d-block', 'd-none')
      this.propStyleSelectElement(propStyle, propStyleFlat, "block", "none");
      this.disableSelect(propStyleFlat, propStyle);
    }
    else if(option == 'f'){
      this.toggleDiv('d-none', 'd-block', true)
      this.propStyleSelectElement(propStyle, propStyleFlat, "none", "block");
      this.disableSelect(propStyle, propStyleFlat);
    }
  }

  disableSelect(propStyleFlat, propStyle) {
    propStyleFlat.setAttribute('disabled', 'disabled');
    propStyle.removeAttribute('disabled');
  }

  propStyleSelectElement(propStyle, propStyleFlat, style1, style2) {
    propStyle.style.display = style1;
    propStyleFlat.style.display = style2;

  }

  toggleDiv(show, hide, isflat= false){
    if(this.hasHouseDescTarget && this.hasFloorTarget){
      let house = this.houseDescTarget.classList
      let floor = this.floorTarget.classList
      let floorBuilding = this.floorBuildingTarget.classList
      this.requiredFields(isflat);
      house.remove(hide)
      house.add(show)
      this.floorDescription(floor, show, hide, floorBuilding);
    }
  }

  floorDescription(floor, show, hide, floorBuilding) {
    floor.remove(show);
    floor.add(hide);
    floorBuilding.remove(show);
    floorBuilding.add(hide);
  }

  requiredFields(isflat) {
    if (isflat) {
      this.houseSelectTarget.removeAttribute('required');
      this.houseSelectTarget.value = ""
      this.floorNumberTarget.setAttribute('required', true);
      this.totalFloorsTarget.setAttribute('required', true);
    }
    else {
      this.houseSelectTarget.setAttribute('required', true);
      this.floorNumberTarget.removeAttribute('required');
      this.totalFloorsTarget.removeAttribute('required');
      this.floorNumberTarget.value = ""
      this.totalFloorsTarget.value = ""
    }
  }
}
