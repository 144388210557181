import { Controller } from "stimulus"
import { DataTable } from 'simple-datatables'

export default class extends Controller {
  connect(){
    $(document).ready(() => {
      this.postcodeTable();
    });
  }

  postcodeTable(){
    let dataTable = new DataTable("#postcodeTable");
  }
}
