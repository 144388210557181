import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'bookmarkList', 'expandBookmark', 'savePosition' ]

  connect() {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('field_id')) {
      this.scrollToField(urlParams)
    } else if (this.hasSavePositionTarget) {
      window.addEventListener('beforeunload', this.savePosition)
      this.restorePosition(window)
    }
    document.querySelector('.nav-list-group li:nth-child(1)').classList.add('active')
  }

  scrollToElement(e){
    let targetId = e.target.dataset['id'];
    let element = document.getElementsByClassName('list-group-item active')[0];
    $('html, body').animate({
      scrollTop: $('#' + targetId).offset().top
    }, 200);
    if (element && element.classList.contains('active')){
      element.className = 'pointer-cursor list-group-item';
    }
    e.target.className = 'pointer-cursor list-group-item active';
  }

  showMoreElement(event){
    let list = this.bookmarkListTarget,
        element = Array.prototype.slice.call(list.querySelectorAll('li'), 4),
        isExpanded = list?.classList.contains('expanded');
      
    if(element.length > 0){
        event.preventDefault();
        this.expandBookmarkTarget.innerText = isExpanded ? 'Show More' : 'Show Less';
        list.classList.toggle('expanded');
    }
  }

  savePosition(e) {
    let currentYOffset = window.pageYOffset
    sessionStorage.setItem('scrollpos', currentYOffset)
  }

  restorePosition(window) {
    let scrollpos = sessionStorage.getItem('scrollpos')
    if (scrollpos) window.scrollTo(0, scrollpos)
    delete sessionStorage.scrollpos
  }

  scrollToField(urlParams) {
    let field = urlParams.get('field_id')
    let fieldElement = document.getElementById(`field_${field}`)
    fieldElement.scrollIntoView({behavior: 'smooth'})
    let urlObj = new URL(window.location.href);
    urlObj.search = ''
    let result = urlObj.toString()
    window.history.pushState('', '', result)
  }
}
