import { Controller } from 'stimulus'
import { applyMixins } from '../lib/helper_functions'
import { EventCalendar } from './mixins/event_calendar'

export default class extends Controller {
  static targets = [ 'selectedOffice', 'accountCalendar' ]

  static values = { surveyors: Array, events: Array, url: String }

  connect() {
    applyMixins(this, [EventCalendar])
    this.initializeCalendar({resources: this.surveyorsValue, events: this.eventsValue, resourceKey: 'surveyor_id', resourceName: 'surveyors', target: this.accountCalendarTarget})
  }

  filterByOffice() {
    let url =  new URL(this.urlValue)
    url.searchParams.append('office_id', this.selectedOfficeTarget.value)
    fetch(url, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.json())
    .then((data) => {
      data['surveyors'].unshift({ id: -1, name: 'Unassigned' })
      this.initializeCalendar({resources: data['surveyors'], events: data['events'], resourceKey: 'surveyor_id', resourceName: 'surveyors', target: this.accountCalendarTarget})
    })
  }
}
