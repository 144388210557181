import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "jsEnabled", "hasDom", "resolution", "viewable",
                     "colorDepth"]

  connect() {
    this.performDiagnosis()
  }

  performDiagnosis() {
    let enabled = this.jsEnabledTarget.children
    enabled[1].innerHTML = "true"
    enabled[2].value = "true"
    this.domPresent()
    this.screenDetails()
  }

  domPresent(){
    let input = document.getElementById("has_dom")
    let containsElement = this.hasDomTarget.contains(input)
    if (input)
      input.value = containsElement
    this.hasDomTarget.children[1].innerHTML = "true"
  }

  screenDetails(){
    // screen resolution
    let resolution = screen.width+ 'px X ' + screen.height  + 'px'
    let userResolution = this.resolutionTarget.children
    userResolution[1].innerHTML = resolution
    userResolution[2].value = resolution

    // viewable screen resolution
    let viewableScreen = screen.availWidth + 'px X ' + screen.availHeight + 'px'
    let screenView = this.viewableTarget.children
    screenView[1].innerHTML = viewableScreen
    screenView[2].value = viewableScreen

    //color depth
    let color = screen.colorDepth
    let userColour = this.colorDepthTarget.children
    userColour[1].innerHTML = color + 'bit'
    userColour[2].value = color
  }
}
