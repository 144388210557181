import { Controller } from "stimulus"
import axios from 'axios'
import { DataTable } from 'simple-datatables'

export default class extends Controller {
  static targets = ['usersList', 'statusChoice', 'organizationChoice', 'surveyorChoice',
                    'salesChoice']

  connect(){
    $(document).ready(() => {
      this.usersDataTable()
    });
  }

  get usersList(){
    return this.usersListTarget
  }
  
  get statusValue(){
    return this.statusChoiceTarget.value
  }

  get organizationValue(){
    return this.organizationChoiceTarget.value
  }

  get surveyorValue(){
    return this.surveyorChoiceTarget.value
  }

  get salesUserValue(){
    return this.salesChoiceTarget.value
  }

  get filterUrl(){
    return this.statusChoiceTarget.dataset.url;
  }

  showByStatus(){
    let statusParams = {
      params: {
        status: this.statusValue
      }
    }
    this.filterResponseData(this.filterUrl, statusParams, this.usersList)
  }

  showOrganizationList(){
    let organizationParams = {
      params: {
        organization:  this.organizationValue,
        status: this.statusValue,
      }
    }
    this.filterResponseData(this.filterUrl, organizationParams, this.usersList)
  }

  showSurveyor(){
    let surveyorParams = {
      params: {
        surveyor: this.surveyorValue,
        status:   this.statusValue,
      }
    }
    this.filterResponseData(this.filterUrl, surveyorParams, this.usersList)
  }

  showSales(){
    let salesUserParams = {
      params: {
        sales:  this.salesUserValue,
        status: this.statusValue,
      }
    }
    this.filterResponseData(this.filterUrl, salesUserParams, this.usersList)
  }

  filterResponseData(requestUrl, params, list){
    axios.get(requestUrl, params, list)
      .then((response)=> {
        list.innerHTML = response.data;
        this.usersDataTable()
      })
  }

  usersDataTable(){
    let dataTable = new DataTable("#userTable", {
      columns: [
          { select: 2, sortable: false }
      ]
    });
  }
}
