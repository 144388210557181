import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {

  static targets = ["selectSurveyors"]

  get selectSurveyor(){
    return this.selectSurveyorsTarget;
  }

  connect(){
    $(this.selectSurveyor).select2({
      placeholder: "select surveyor user",
      allowClear: true
    }).change((event) => {
      let element = event.target.selectedOptions;
      const competencyElement = document.getElementById("officeCompetency");
      let url = event.target.dataset.url;
      let surveyor_ids = [...element].map(option => option.value);
      let surveyor_params = {
        params:  {surveyor_ids: surveyor_ids},
        headers: {'Content-Type':'application/json','Accept':'application/json'} 
      }
      axios.get(url, surveyor_params)
        .then((response)=> {
          let data = response.data;
          competencyElement.innerHTML = "";
          competencyElement.innerHTML = data;
        })
    });
  }
}

