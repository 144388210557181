import { Controller } from 'stimulus'
import axios from 'axios'
import { DataTable } from 'simple-datatables'

export default class extends Controller {
  static targets = ['accountChoice', 'accountsList', 'clientAccount', 'fieldsList', 'statusChoice', 'profession', 'toolTypeChoice', 'signUpList']

  static values = { url: String }

  connect() {
    if (this.hasAccountsListTarget)
      this.showOrganizationList()
    else {
      this.showSignUpList()
    }
  }

  filterResponseData(requestUrl, params, list) {
    axios.get(requestUrl, params, list)
      .then((response) => {
        list.innerHTML = response.data
        this.dataTable()
      })
  }

  showOrganizationList() {
    let organizationTypeValue = this.professionTarget.querySelector('#organization-type a.active').dataset.value
    let onAccountValue = organizationTypeValue === 'client' ? this.accountChoiceTarget.value : null
    let toolTypeValue = organizationTypeValue === 'client' ? null : this.toolTypeChoiceTarget.value
    let organization_type_params = {
      params: {
        status_filter: this.statusChoiceTarget.value,
        organization_type: organizationTypeValue || 'surveying_firm',
        on_account_filter: onAccountValue,
        tool_type_filter: toolTypeValue
      }
    }
    this.filterResponseData(this.urlValue, organization_type_params, this.accountsListTarget)
  }

  showReportFieldsList() {
    let params = {
      params: {
        account_id: this.clientAccountTarget.value
      }
    }
    this.filterResponseData(this.clientAccountTarget.dataset.url, params, this.fieldsListTarget)
  }

  dataTable() {
    let [tableId, index] = this.hasAccountsListTarget ? ['#accountTable', 6] : ['#signUpTable', 3]
    let dataTable = new DataTable(tableId, {
      columns: [
        { select: index, sortable: false }
      ]
    })
  }

  showSignUpList() {
    let params = {
      params: {
        status: this.statusChoiceTarget.value
      }
    }
    this.filterResponseData(this.urlValue, params, this.signUpListTarget)
  }
}
