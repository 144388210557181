import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {

  static targets = [ "panels", "accountId"]

  initialize(){
    this.panelMembers(this.firstInstructionType);
  }

  get panelsList(){
    return this.panelsTarget
  }

  get accountId(){
    return this.accountIdTarget.dataset.account;
  }

  get firstInstructionType(){
    return this.accountIdTarget.dataset.instructiontype;
  }

  changeTypeContent(e){
    let competency = e.target.dataset.instype;
    this.panelMembers(competency);
  }

  panelMembers(competency){
    let comp = competency
    let accountId = this.accountId;
    let list = this.panelsList;
    list.innerHTML = "";
    let params = {
      params: {
        headers: {'Content-Type':'application/json','Accept':'application/json'},
        competency: comp
      }
    }
    axios.get(`/admins/accounts/${accountId}/survey_types/panel_members`, params)
      .then((response)=> {
        list.innerHTML = response.data;
      })
  }
}
