import { Controller } from 'stimulus'
import { toggleDisplay, toggleClasses } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ['organizationType', 'groupSection', 'groupAccount', 'piSection', 'toolType', 'onAccount', 'vatSection']

  connect() {
    this.toggleDependentOrgTypeFields()
    this.togglePISection()
  }

  toggleDependentOrgTypeFields() {
    let condition = this.organizationTypeTarget.value === 'surveying_firm'
    toggleDisplay(this.groupSectionTarget, condition)
    toggleDisplay(this.toolTypeTarget, condition)
    toggleDisplay(this.onAccountTarget, !condition)
    toggleDisplay(this.vatSectionTarget, condition)

    if (!this.hasPiSectionTarget) return

    toggleDisplay(this.piSectionTarget, condition)
  }

  togglePISection() {
    if (!this.hasPiSectionTarget) return

    toggleDisplay(this.piSectionTarget, !this.groupAccountTarget.checked)
  }
}
