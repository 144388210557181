import { Controller } from 'stimulus';
import { getMetaValue, toggleClasses, clipboardCopyHandler } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ['tokenInput', 'generatebtn']

  static values = { url: String }

  fetchToken() {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        this.tokenInputTarget.value = data.token
      })
      .catch(error => console.error('Error generating token:', error))
  }

  generateToken(e) {
    fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaValue('csrf-token')
        }
      })
      .then(response => response.json())
      .then(data => {
        this.tokenInputTarget.textContent = data.token
        toggleClasses(this.generatebtnTarget, 'd-block', 'd-none')
      })
      .catch(error => console.error('Error generating token:', error))
  }

  copyToClipboard(e) {
    clipboardCopyHandler(e, 'copied-clipboard')
  }
}
