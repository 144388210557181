import { Controller } from "stimulus"
import LoadMapController from './load_map_controller'

export default class extends LoadMapController {
  static values = { locations: Array, latitude: String, longitude: String }

  connect() {
    this.showMapView(this.longitudeValue, this.latitudeValue, 14)
    this.initializeVariable()
  }

  onMapLoaded() {
    this.getGeojsonData(this.locationsValue)
  }

  addMarkerOnMap(comparablesegeojson) {
    comparablesegeojson.features.forEach(marker => {
      const el = document.createElement('div')
      let mapMarker
      if (marker.geojson) {
        el.className = 'marker'
        mapMarker = marker.geojson.data
      } else {
        el.className = 'postcode-marker'
        mapMarker = marker.instruction_geojson.data
      }
      this.panelFeature.createMarker(this.map, el, mapMarker)
      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, mapMarker, this.map)
      })

      return el.addEventListener('click', (e) => {
        this.panelFeature.flyToPanel(this.map, mapMarker.geometry.coordinates)
      })
    })
  }
}
