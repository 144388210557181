import { Controller } from "stimulus"
import { toggleClasses } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ['signoffButton', 'showMoreLink', 'incompletedFields', 'signOffSpinner']

  toggleButton(e){
    this.signoffButtonTarget.disabled = !e.target.checked
  }

  displayFields(fieldListStyle, linkStyle){
    this.incompletedFieldsTarget.style.display = fieldListStyle
    this.showMoreLinkTarget.style.display = linkStyle
  }

  moreFields(){
    this.displayFields('block', 'none')
  }

  lessFields(){
    this.displayFields('none', 'block')
  }

  resignOffForm() {
    toggleClasses(this.signOffSpinnerTarget, 'd-none', 'd-flex')
  }
}
