import { Controller } from 'stimulus'
import axios from 'axios'
import { addSelectOption } from '../lib/helper_functions'

export default class ReferralFilterController extends Controller {

  static targets = ['referralData']

  static values = { url: String }

  connect() {
    selectFilter(this);
  }

  changeSelectPartnerCategory(e) {
    let partnerCategory = e.target.value
    let url = this.urlValue
    let params = { params: { partner_category: partnerCategory }, headers: { 'Content-Type':'application/json', 'Accept':'application/json' } }

    axios.get(url, params)
    .then((response)=> {

      let responseData = response.data
      const referralElement = this.referralDataTarget
      referralElement.innerHTML = ''

      if (responseData.length > 0){
        addSelectOption(referralElement, 'Please Select')
        responseData.forEach((value, index) => {
          let options = referralElement.appendChild(document.createElement('option'))

          options.text = value.name
          options.value = value.id
        })
      } else{
        addSelectOption(referralElement, 'N/A')
      }
      referralElement.options[0].value = ''
    })
  }
}
