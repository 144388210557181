export const pasteTextWithFormat = (e) => {
  e.preventDefault()

  let text = replaceCarriageWithBrTag(e.clipboardData.getData('text/plain'))
  let sel = window.getSelection()
  let range = sel.getRangeAt(0)
  range.deleteContents()
  let newNode = document.createElement('span')
  newNode.innerHTML = text
  range.insertNode(newNode)
}

export const replaceCarriageWithBrTag = (text) => {
  return text.replace(/\r?\n|\r/g, '<br>')
}

export const smartElement = (result, fieldId) => {
  let smartTextNum = 0
  result = result.replace(/\|\{(.+?)\}\|/g, (text) => {
    smartTextNum++
    return `<span id="smart-text-button-${fieldId}-${smartTextNum}" class="smart-element"><span contenteditable="false" data-toggle="modal" data-target="#smartResponseModal" class="text-primary pointer-cursor" data-action="click->paragraph#showCheckboxes" data-text="${text}" data-field-id="${fieldId}">${text}</span></span>`
  })
  return result
}
