import { Controller } from 'stimulus'
import { applyMixins } from '../lib/helper_functions'
import { FileUploader } from './mixins/file_uploader'

export default class extends Controller {
  static values = { url: String, maxFiles: Number }

  connect(){
    applyMixins(this, [FileUploader])
    this.initializeUploader(this.urlValue, {
      maxNumberOfFiles: this.maxFilesValue,
      redirectUrl: this.urlValue.replace('.json', '')
    })
  }
}
