import { Controller } from 'stimulus'
import { PropertyType } from './mixins/property_type'
import { applyMixins, disableButton } from '../lib/helper_functions'

export default class extends Controller {
  static targets = [ 'section', 'getSurveyType', 'commercialProperty', 'residentialProperty',
                    'developmentProperty', 'propertyType', 'propType', 'customField',
                    "bedroomsLabel",
                    "bedroomsInput" ]

  connect() {
    applyMixins(this, [PropertyType])
    this.showSection(0)
    this.updatePropertyTypeOptions(this.getSurveyTypeTarget)
  }

  nextStep() {
    if (this.validateCurrentSection()) {
      const currentSectionIndex = this.currentSectionIndex()
      if (currentSectionIndex < this.sectionTargets.length - 1) {
        this.showSection(currentSectionIndex + 1)
      }
    }
  }

  backStep() {
    const currentSectionIndex = this.currentSectionIndex()
    if (currentSectionIndex > 0) {
      this.showSection(currentSectionIndex - 1)
    }
  }

  showSection(index) {
    this.sectionTargets.forEach((section, i) => {
      section.classList.toggle('d-none', i !== index)
    })
  }

  currentSectionIndex() {
    return this.sectionTargets.findIndex(section => !section.classList.contains('d-none'))
  }

  validateCurrentSection() {
    const currentSection = this.sectionTargets[this.currentSectionIndex()]
    const requiredFields = currentSection.querySelectorAll('[required]')

    for (const field of requiredFields) {
      if (!field.value.trim()) {
        field.setCustomValidity('Please fill the required field')
        field.reportValidity()
        field.setCustomValidity('')
        return false
      } else {
        field.setCustomValidity('')
      }
    }
    return true
  }

  instantQuoteSurveyTypeList(){
    this.updatePropertyTypeOptions(this.getSurveyTypeTarget)
  }

  // on the basis of instruction type it will append the survey type to survey type dropdown
  fetchActiveSurveyTypes(event){
    this.selectSurveyTypeList(event)
  }
}
