// app/javascript/controllers/background_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { backgroundColor: String }

  connect() {
    this.applyBackgroundColor()
  }

  applyBackgroundColor() {
    const backgroundColor = this.backgroundColorValue

    if (backgroundColor) {
      document.body.style.setProperty('background-color', backgroundColor, 'important')

      const cardElements = document.querySelectorAll('.custom-card')
      cardElements.forEach(cardElement => {
        cardElement.style.backgroundColor = backgroundColor
      })
    }
  }
}
