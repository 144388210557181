import { Controller } from 'stimulus'
import { toggleClasses } from '../lib/helper_functions'

export default class extends Controller {
  static targets = [ 'officeReviewBadge' ]

  toggleOfficeReviewBadge(e) {
    if (!this.hasOfficeReviewBadgeTarget) return

    if (e.target.ariaExpanded === 'true' || e.type === 'blur') {
      toggleClasses(this.officeReviewBadgeTarget, 'd-none', 'd-block')
    } else {
      toggleClasses(this.officeReviewBadgeTarget, 'd-block', 'd-none')
    }
  }

  filterOptions() {
    let inputValue = event.target.value.toUpperCase()
    let div = event.target.parentElement
    let options = div.getElementsByTagName('a')
    Array.from(options).forEach((option) => {
      if (option.innerText.toUpperCase().indexOf(inputValue) > -1) {
        option.style.display = ''
      } else {
        option.style.display = 'none'
      }
    });
  }
}
