export const getMetaValue = (name) => {
  const element = document.head.querySelector(`meta[name='${name}']`)
  return element.getAttribute('content')
}

export const toggleClasses = (el, removeClass, addClass) => {
  el?.classList.remove(removeClass)
  el?.classList.add(addClass)
}

export const toggleDisplay = (el, condition = true) => {
  if (condition) {
    el.style.display = 'block'
  } else {
    el.style.display = 'none'
  }
}

export const addSelectOption = (el, content) => {
  const option = document.createElement('option')
  option.innerHTML = content
  el.appendChild(option)
}

export const applyMixin = (mixin) => {
  return (target) => {
    Object.assign(target.prototype, mixin)
  }
}

export const applyMixins = (target, mixins) => {
  mixins.forEach(mixin => Object.assign(target, mixin))
}

export const disableButton = (element) => {
  setTimeout(() => {
    element.disabled = true
  })
}

export const togglePassword = (target) => {
  target.type = target.type === 'password' ? 'text' : 'password'
}

export const disableScrollWheel = (element) => {
  let disableWheel = (event) => {
    event.preventDefault()
  }
  element.addEventListener('wheel', disableWheel)
}

export const clearInputFields = (target) => {
  let input = target.getElementsByTagName('input')
  let inputFields = Array.prototype.slice.call(input)
  inputFields.forEach((fields) => {
    if (fields.type === 'radio') {
      fields.checked = false
    } else {
      fields.value = ''
    }
  })
}

export const handleError = (error) => {
  if (error.message === '404'){
    showToast('Resource not found')
  }
}

export const requestHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const fetchPostRequest = (url, bodyParams) => {
  let headers = requestHeaders
  headers['X-CSRF-Token'] = getMetaValue('csrf-token')

  return fetch(url, {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(bodyParams)
  })
}

const showToast = (message) => {
  const alert = document.createElement('div');
  alert.className = 'alert alert-danger alert-dismissible fade show flash-message';
  alert.role = 'alert';
  alert.innerHTML = `
    ${message}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  `;
  document.body.appendChild(alert);

  setTimeout(() => {
    alert.classList.remove('show');
    alert.classList.add('hide');
    setTimeout(() => {
      document.body.removeChild(alert);
    }, 500);
  }, 5000);
}

export const clipboardCopyHandler = async (element, className) =>{
  element.target.classList.remove(className)
  const tokenElement = element.target.dataset.apiToken
  await navigator.clipboard.writeText(tokenElement)
  element.target.classList.add(className)
}

