import { Controller } from 'stimulus'
import { toggleClasses } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ['surveyorFields', 'designation', 'competencies', 'ricsNumber', 'avatar', 'qualificationDate', 'companyAvatar', 'surveyTypes',
    'surveyor', 'professionalBody']

  toggleSurveyorFields() {
    if (this.surveyorUser()) {
      toggleClasses(this.surveyorFieldsTarget, 'd-none', 'd-block')
      this.requireDesignationField(true)
    }
    else {
      toggleClasses(this.surveyorFieldsTarget, 'd-block', 'd-none')
      this.requireDesignationField(false)
      this.updateValues()
    }
  }

  requireDesignationField(value) {
    this.designationTarget.required = value
  }

  addDesignationValue() {
    this.designationTarget.value = event.target.dataset.value
    this.requireRicsQualificationFields()
  }

  requireRicsQualificationFields() {
    let value = this.designationTarget.value.includes('RICS')

    this.ricsNumberTarget.required = value
    this.qualificationDateTarget.required = value
  }

  updateValues() {
    this.designationTarget.value = ''
    this.competenciesTargets.forEach(function (competency) {
      if (competency.checked)
        competency.checked = false
    })
    this.ricsNumberTarget.value = ''
    this.qualificationDateTarget.value = ''
  }

  toggelCompanyAvatar() {
    this.companyAvatarTarget.required = !event.target.checked
  }

  checkSurveyTypeAndCompetencies() {
    this.surveyTypeValidation()
    this.competenciesValidation()
  }

  surveyTypeValidation() {
    let checked = this.surveyTypesTargets.some((checkbox) => checkbox.checked)
    if (!checked) {
      event.preventDefault()
      alert("at least one survey type must be selected in order to submit the form")
    }
  }

  competenciesValidation() {
    let checked = this.competenciesTargets.some((checkbox) => checkbox.checked)
    if (this.surveyorUser() && !checked) {
      event.preventDefault()
      alert("At least one competency must be selected in order to submit the form")
    }
  }

  surveyorUser() {
    return this.surveyorTarget.value == 'true'
  }

  addProfessionalBodyValue() {
    this.professionalBodyTarget.value = event.target.dataset.value
  }
}
