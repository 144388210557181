import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["reportImageGallery"]

  connect(){
    let imageSection = this.reportImageGalleryTarget;
    let firmInterface = imageSection.dataset.firmInterface;
    let navigationEntries = window.performance.getEntriesByType("navigation")
    if (firmInterface == "true" && navigationEntries.length > 0 && navigationEntries[0].type == "navigate" && navigationEntries[0].name.includes("/uploads")){
      imageSection.scrollIntoView()
    }
  }
}
