import { Controller } from "stimulus"
import { getMetaValue } from '../lib/helper_functions'

export default class extends Controller {
  static targets = ["labelName", "labelColor", "labelList", "questionCommentBox",
                    "questionCheck", "questionTextArea" ]

  connect(){
    $('#note_label_list').select2({
      multiple: true,
      placeholder: "--select labels--",
      templateSelection: function formatLabel(item){
                            let color = item.element.dataset.color;
                            return $(
                              `<span><span style="background-color:${color}; color: ${color}" class="label-color"></span><span>${item.text}</span></span>`
                            );
                        }
    });
    
  }

  addLabel(){
    let name = this.labelNameTarget
    let color = this.labelColorTarget
    let labelParams = {
      label_name:  name.value,
      label_color: color.value
    }
    fetch(`/admins/addon_notes/add_new_label`, {
      headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': getMetaValue('csrf-token')
            },
      method: 'POST',
      body: JSON.stringify(labelParams)
    }).then(response => response.json())
    .then((data)=> {
      if(data['message'] != undefined){
        this.ShowLabelError(data)
      }
      else{
        this.AddNewLabelToList(data, name, color)
      }
    })
  }

  AddNewLabelToList(data, name, color){
    let label = this.labelListTarget
    let option = document.createElement("option")
    let label_option = data["label_options"]
    option.text = label_option.name
    option.value = label_option.name
    option.dataset.color = label_option.color
    label.add(option);
    name.value = ""
    color.value = ""
    document.getElementById("new-label").classList.remove('show')
  }

  ShowLabelError(data){
    setTimeout(function () {
      document.getElementById('label-error').innerHTML = ''
    }, 3000);
    document.getElementById('label-error').innerHTML = data['message']
  }

  displayQuestionCommentBox(){
    let questionCheckBox = this.questionCheckTarget;
    let questionCommentBox = this.questionCommentBoxTarget;
    let questionTextArea = this.questionTextAreaTarget;
    if (questionCheckBox.checked){
      questionCommentBox.classList.add('d-none')
      questionTextArea.removeAttribute('required')
      questionTextArea.value = ""
    } else {
      questionCommentBox.classList.remove('d-none')
      questionTextArea.setAttribute('required', true)
    }
  }
}
