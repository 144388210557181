import { Calendar } from '@fullcalendar/core'
import enLocale from '@fullcalendar/core/locales/en-au'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { groupBy, merge, omit } from 'lodash'
import { getMetaValue } from '../../lib/helper_functions'
import { EventCalendarHelper } from './event_calendar_helper'

export const EventCalendar = {
  ...EventCalendarHelper,

  maxResourceLimit: 10,
  calendar: null,
  eventResources: [],
  maxRenderableResources: [],
  hideEditEventButton: false,

  get colorList() {
    return ['lightseagreen', 'deepskyblue', 'orange', 'mediumpurple', 'orchid',
            'olivedrab', 'royalblue', 'darkgoldenrod', 'indianred', 'hotpink',
            'lightgreen', 'cornflowerblue', 'saddlebrown', 'coral']
  },

  calendarEventDidMount(info) {
    let eventDetails = info.event.extendedProps

    let content = () => {
      if(eventDetails.postcode) {
        return this.instructionEventPopup(info, eventDetails)
      } else {
        return this.customEventPopup(info, eventDetails, this.hideEditEventButton)
      }
    }

    $(info.el).popover({
      content,
      template: `<div class="fc-popover popover" role="tooltip">
                  <div class="arrow"></div>
                  <h3 class="popover-header"></h3>
                  <div class="popover-body"></div>
                </div>`,
      html: true,
      sanitize: false,
      container: 'body'
    })
  },

  calendarViewDidMount() {
    document.querySelector('body').addEventListener('click', function (e) {
      document.querySelectorAll('.fc-event').forEach(function (el) {
          // hide any open popovers when the anywhere else in the body is clicked
          if ((!$(el).is(e.target) && $(el).has(e.target).length === 0 && $('.fc-popover').has(e.target).length === 0) || e.target.dataset.value === 'editCustomEvent') {
            $(el).popover('hide')
          }
      })
    })
  },

  calendarConfiguration() {
    return {
      locale: enLocale,
      timeZone: 'Europe/London',
      plugins: [ bootstrapPlugin, timeGridPlugin, listPlugin ],
      themeSystem: 'bootstrap',
      initialView: 'timeGridWeek',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,listWeek,timeGridDay'
      },
      eventTimeFormat: { hour: 'numeric', minute: '2-digit', meridiem: 'short' },
      eventColor: 'lightseagreen',
      eventDidMount: this.calendarEventDidMount.bind(this),
      viewDidMount: this.calendarViewDidMount,
      allDaySlot: false,
      nowIndicator: true,
      scrollTime: '08:00:00',
      dayMaxEvents: true, // allow "more" link when too many events
      eventContent: function(info) {
        const title = info.event.title.substring(0, 15)
        const html = info.event.extendedProps.postcode ? `<strong>${info.event.title} (${info.event.extendedProps.postcode})</strong>` : `<strong>${title}</strong>`
        return { html }
      }
    }
  },

  renderableResources(resources = []) {
    if (!resources.length)
      resources = this.maxRenderableResources
    resources.map((resource, idx) => {
      let color = resource.id === -1 ? 'lightslategrey' : this.colorList[idx % this.colorList.length]
      merge(resource, { color: color })
    })
    return resources
  },

  selectedEventSources() {
    let selectedResources = $('#resources-select').select2('data').map((resource) => resource.id)
    let filteredResources = selectedResources.length ?
                              this.eventResources.filter(resource => selectedResources.includes(resource.id.toString())) :
                              this.maxRenderableResources

    let renderableResources = this.renderableResources(filteredResources)
    this.renderResourceList(renderableResources)
    this.renderCalendar(this.calendar.el, { eventSources: renderableResources })
  },

  addResourceSelect(resourcePanel) {
    resourcePanel.innerHTML += `
      <div class="mb-5 text-center">
        <select name="resources[]" id="resources-select" class="form-control" multiple="multiple"></select>
        <button class="btn btn-secondary btn-sm button-height mt-2" data-action="click->${this.identifier}#selectedEventSources"><small>Apply</small></button>
      </div>
    `

    let selectBox = document.getElementById('resources-select')
    this.eventResources.forEach(resource => {
      let optn = document.createElement('OPTION')
      optn.text = resource.name
      optn.value = resource.id
      selectBox.options.add(optn)
    })
  },

  toggleResource(e) {
    let resourceId = e.target.dataset.resourceId
    if (e.target.checked) {
      let eventSource = this.eventResources.find(resource => resource.id.toString() === resourceId)
      this.calendar.addEventSource(eventSource)
    } else {
      let eventSource = this.calendar.getEventSourceById(resourceId)
      eventSource?.remove()
    }
  },

  renderResourceList(resources) {
    let resourceListArea = document.getElementById('resource-list')
    resourceListArea.innerHTML = ''

    resources.forEach(resource => {
      let li = document.createElement('li')
      li.className = 'list-group-item border-0 px-2 py-2'
      li.innerHTML += `
        <span class="p-0 col-md-2 float-left mt-2" style="height:0.8rem; width:0.8rem; background-color:${resource.color}"></span>
        <span class="p-0 col-md-9 name-wrap float-left mx-1"><small>${resource.name}</small></span>
        <span class="p-0 col-md-1 float-left align-middle">
          <input checked class="float-right mt-2" data-action="click->${this.identifier}#toggleResource" type="checkbox" data-resource-id="${resource.id}">
        </span>
      `
      resourceListArea.appendChild(li)
    })
  },

  addResourcePanel(parentElement, resourceName = 'resources') {
    let resourcePanel = this.addChildElement(parentElement, 'fc-resource-panel col-md-2 float-left pl-0 bg-grey')
    this.addResourceSelect(resourcePanel)

    resourcePanel.innerHTML += `<div class="mb-2"><ul id="resource-list" class="list-group"></ul></div>`
    this.renderResourceList(this.renderableResources())

    let placeholder = `First ${this.maxResourceLimit} ${resourceName} events`
    $('#resources-select').select2({ placeholder: placeholder, maximumSelectionLength: this.maxResourceLimit })
  },

  buildEventResources(resources, events, resourceKey) {
    if (!!resourceKey)
      events = events.map((event) => merge(event, { resourceId: event[resourceKey]}))

    let eventsByResource = groupBy(events, 'resourceId')
    this.eventResources = resources.map((resource, idx) => merge(resource, {events: eventsByResource[resource.id] || []}))
    this.maxRenderableResources = this.eventResources.slice(0, this.maxResourceLimit)
  },

  initializeCalendar(options = {}) {
    let target = options.target || document.getElementById('event-calendar')
    target.innerHTML = ''
    let events = options.events.map(event => ({ ...event, surveyor_id: event.surveyor_id || -1 }))
    this.hideEditEventButton = options.hideEditEventButton || this.hideEditEventButton

    let calendarOptions = {}
    let hasEventResources = options.resources?.length
    if (hasEventResources) {
      this.buildEventResources(options.resources, events, options.resourceKey)
      this.addResourcePanel(target, options.resourceName)
      calendarOptions = { eventSources: this.renderableResources() }
    } else {
      calendarOptions = { events: events }
    }

    let calendarClass = hasEventResources ? 'col-md-10 float-right' : 'col-md-12'
    let calendarArea = this.addChildElement(target, `pb-5 ${calendarClass}`)
    this.renderCalendar(calendarArea, calendarOptions)
  },

  renderCalendar(calendarElement, options = {}) {
    let calenderOptions = merge(omit(this.calendarConfiguration(), 'eventSources'), options)
    this.calendar = new Calendar(calendarElement, calenderOptions)
    this.calendar.render()
  },

  visualizeEvent(event) {
    event.resourceId = event.resourceId || -1
    event.classNames = 'current-instruction-blink'
    this.removeEventFromCalendar(event.id, event.resourceId)
    this.addEventToCalendar(event)
  },

  addUpdateCustomEvent(event) {
    let formElement = event.target.form
    if (this.validateForm(formElement)) {
      event.preventDefault()

      let formData = new FormData(document.getElementById(formElement.id))
      let requestUrl = formElement.getAttribute('action') + '?' + new URLSearchParams(formData).toString()
      let method = formElement.id === 'new_custom_event' ? 'POST' : 'PATCH'
      fetch(requestUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaValue('csrf-token')
        }
      })
      .then(response => {
        if (response.ok)
          return response.json()
        throw response
      })
      .then(data => {
        this.showCustomEventOnCalendar(data)
      })
      .catch(error => {
        this.customEventError(error)        
      })
    }
  },

  showCustomEventOnCalendar(data) {
    $('#customEvent').modal('hide')

    let event = data.event
    event.resourceId = event.surveyor_id
    this.removeEventFromCalendar(event.id, data.previous_resource_id)
    this.addEventToCalendar(event)
  },

  deleteCustomEvent(e) {
    if (confirm('Are you sure you want to delete this event?')) {
      let requestUrl = e.target.dataset.url
      let eventId = e.target.dataset.eventId
      fetch(requestUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaValue('csrf-token')
        }
      })
      .then(response => response.json())
      .then((data) => {
        $('#customEvent').modal('hide')
        this.removeEventFromCalendar(eventId, data.previous_resource_id)
      })
    }
  },

  removeEventFromCalendar(eventId, previousResourceId) {
    this.calendar.getEventById(eventId)?.remove()
    if (previousResourceId) {
      let resourceIndex = this.eventResources.findIndex(resource => resource.id === previousResourceId)
      if (resourceIndex !== -1) {
        this.eventResources[resourceIndex].events = this.eventResources[resourceIndex].events.filter(item => item.id !== eventId)
      }
    }
  },

  addEventToCalendar(event) {
    let eventSource = this.calendar.getEventSourceById(event.resourceId)
    if((this.eventResources.length > 0) === !!eventSource) {
      this.calendar.addEvent(event, eventSource)
    }
    let resourceIndex = this.eventResources.findIndex(resource => resource.id === event.resourceId)
    if (resourceIndex !== -1) {
      this.eventResources[resourceIndex].events.push(event)
    }
  }
}
