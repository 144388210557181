import { Controller } from "stimulus"
import axios from 'axios'
import { DataTable } from 'simple-datatables'
import { toggleClasses } from '../lib/helper_functions'
import { clearInputFields } from '../lib/helper_functions'

export default class extends Controller {

  static targets = [ 'thresholdDiv', 'getSurveyType', 'reportChoice', 'descriptionContent', 'helpText',
                     'applyThreshold', 'thresholdInput', 'thresholdValue', 'doubleSignature', 'feesVisible',
                     'feeScale', 'feesList', 'reportImageRequired', 'reportImageRequireBlock', 'templateUploadBlock', 'documentBlock',
                     'pastdueInput', 'pastdueDiv', 'businessDay', 'activeCheckbox', 'validationMessage', 'submitButton']

  static values = { path: String, accountId: String, customReport: String, attachedReport: String, mergeFields: String }

  connect(){
    if (this.pathValue != 'quotation'){
      this.requiredImages();
      this.toggleSignatureAndFees()
      this.toggleFeeVisible()
      this.updateTemplateOptionsDisplay()
      this.validate()
    }
  }

  toggleRequiredImagesCheckBox(displayStyle) {
    this.reportImageRequireBlockTarget.style.display = displayStyle;
  }

  requiredImages() {
    if (this.reportChoiceTarget.value == "custom_report") {
      this.templateUploadBlockTarget.classList.add('d-none')
      this.toggleRequiredImagesCheckBox("none");
    }
    else {
      let surveyTypeDocument = this.documentBlockTarget.dataset.accessDocument
      if (surveyTypeDocument == 'false') {
        this.documentBlockTarget.classList.add('d-none')
      } else {
        this.templateUploadBlockTarget.classList.remove('d-none')
      }
      this.toggleRequiredImagesCheckBox("block");
      let checkboxValue = this.reportImageRequiredTarget.dataset.value;
      this.reportImageRequiredTarget.checked = checkboxValue == "true" ? true : false;
    }
  }

  toggleButton(e){
    if(e.target.checked) {
      this.thresholdDivTarget.classList.remove("d-none")
    }else{
      this.emptyThresholdValue();
    }
  }

  getDescription(e){
    let params = new URLSearchParams({ id: e.target.value })
    let requestUrl = `${e.target.dataset.url}?${params.toString()}`
    let surveyTypeDescription = e.target.closest("[class='survey-description']")
    let descriptionContent = surveyTypeDescription.querySelector('.description-content')
    let inspectionTarget = document.getElementById(e.target.dataset.surveyType)
    if (e.target.value) {
      toggleClasses(inspectionTarget, 'd-none', 'd-block')
      fetch(requestUrl)
        .then(response => {
          if (response.ok)
            return response.text()
          throw response
        })
        .then(data =>this.handleDescriptionContent(e, data, descriptionContent))
        .catch(error => {
          console.error('Fetch error:', error)
        })
    } else {
      // works only when creating new quotation
      this.clearAlternativeSurveyTypeContent(surveyTypeDescription, descriptionContent, inspectionTarget)
    }
  }

  toggleThreshold(e){
    if(e.target.checked) {
      this.showThresholdDiv()
    }else{
      this.applyThresholdTarget.classList.add("d-none")
      this.thresholdInputTarget.checked = false;
      this.emptyThresholdValue();
    }
  }

  emptyThresholdValue(){
    this.thresholdDivTarget.classList.add("d-none")
    this.thresholdValueTarget.value = ""
    this.thresholdValueTarget.required = false
  }

  showThresholdDiv(){
    this.applyThresholdTarget.classList.remove("d-none")
  }

  toggleFees(){
    this.toggleFeeVisible()
    let feesListDiv = this.feesListTarget
    if (this.showFeeList()){
      this.toggleFeesBlock('block')
      let params = {
        params: {
          fee_scale_id: this.feeScaleTarget.value
        }
      }
      axios.get(this.feesListTarget.dataset.url, params)
        .then((response)=> {
          feesListDiv.innerHTML = response.data;
          this.showMoreContent();
        })
    }
    else {
      feesListDiv.innerHTML = ""
      this.toggleFeesBlock('none')
    }
  }

  toggleFeesBlock(className){
    let feesListDiv = this.feesListTarget
    feesListDiv.style.display = className
  }

  showMoreContent(){
    let dataTable = new DataTable("#fee-table", {
      columns: [{ select: 0, sortable: false }],
      searchable: false
    });
  }

  toggleSignatureAndFees(){
    if(this.doubleSignatureTarget.checked){
      this.showThresholdDiv()
    }
    if(this.showFeeList()){
      this.toggleFees();
    }
  }

  toggleFeeVisible(){
    if(this.hasFeesVisibleTarget && this.feeScaleTarget.value == ''){
      this.feesVisibleTarget.disabled = true
    }else{
      if(this.hasfeesVisibleTarget){
        this.feesVisibleTarget.disabled = false
      }
    }
  }

  showFeeList(){
    let feeList = this.feesListTarget.dataset
    return (this.hasFeesVisibleTarget && this.feesVisibleTarget.checked) || (feeList.feeVisibility == "true" && feeList.isAdmin == "false")
  }

  updateTemplateOptionsDisplay(){
    if (this.reportChoiceTarget.value == "custom_report") {
      this.toggleElements("none", "d-none");
    } else { 
      this.toggleElements("block", "d-none");
    }

    var reportSource = this.toCamelCase(this.reportChoiceTarget.value);
    this.helpTextTarget.innerHTML = this[`${reportSource}Value`];
  }

  toCamelCase(text) {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  }

  toggleElements(show, hide){
    this.toggleRequiredImagesCheckBox(show);
    this.templateUploadBlockTarget.classList.remove(hide)
  }

  pastDueToggleButton(e){
    if(e.target.checked) {
      this.pastdueDivTarget.classList.remove("d-none")
    }else{
      this.resetPastdueValue();
    }
  }

  resetPastdueValue(){
    this.pastdueDivTarget.classList.add("d-none")
    this.businessDayTarget.value = ""
    this.businessDayTarget.required = false
  }

  getDescriptionContent(e){
    let url = `/accounts/${this.accountIdValue}/fetch_quotation_type`
    let params = { params: { id: e.target.value } }
    axios.get(url, params)
      .then((response)=> {
        this.descriptionContentTarget.innerHTML = response.data;
    });
  }

  showInspectionDate(e){
    var inspectionDate = document.getElementById('recommended').querySelector('select.inspection-date').value
    var inspectionTarget = document.getElementById(e.target.dataset.surveyType)
    if (e.target.dataset.surveyType === 'recommended') {
      toggleClasses(inspectionTarget, 'd-none', 'd-block')
    }
    inspectionTarget.querySelector('select.inspection-date').value = inspectionDate
  }

  clearAlternativeSurveyTypeContent(surveyTypeDescription, descriptionContent, inspectionTarget) {
    descriptionContent.innerHTML = ''
    clearInputFields(surveyTypeDescription)
    toggleClasses(inspectionTarget, 'd-block', 'd-none')
    let selectElements = surveyTypeDescription.getElementsByTagName('select')
    var selectFields = Array.prototype.slice.call(selectElements)
    selectFields.forEach((selectfield ) => {
      selectfield.selectedIndex = (selectfield.id === 'fee_scale_list') ? 1 : 0
    })
  }

  removeAlertnativeSurveyType(e) {
    let surveyTypeInfo = e.target.dataset.surveyTypeInfo
    let alternativeSurveyTypeBlock = document.getElementById(`alternative-survey-type-${surveyTypeInfo}`)
    alternativeSurveyTypeBlock.classList.add('d-none')
    alternativeSurveyTypeBlock.previousElementSibling.classList.add('d-none')
    e.target.classList.add('d-none')
    let destroyField = document.getElementById(`quotation_quotation_survey_types_attributes_${surveyTypeInfo}__destroy`)
    destroyField.value = true
  }

  handleDescriptionContent(e, data, descriptionContent) {
    if (e.target.dataset.quotation === 'true') {
        descriptionContent.innerHTML = data
    } else {
        this.descriptionContentTarget.innerHTML = data
    }
  }

  validate() {
    const isValid = this.checkAllActiveCheckboxes()
    if (!isValid) {
      this.validationMessageTarget.classList.remove('d-none')
    } else {
      this.validationMessageTarget.classList.add('d-none')
    }
    this.submitButtonTarget.disabled = !isValid
  }


checkAllActiveCheckboxes() {
  return Array.from(this.activeCheckboxTargets).every(activeCheckbox => {
    const index = activeCheckbox.dataset.index
    const feeScaleElement = this.feeScaleTargets.find(target => target.dataset.index === index)
    const isActiveChecked = activeCheckbox.checked
    const feeScaleValue = feeScaleElement?.selectedIndex != 0

    return (!feeScaleValue && !isActiveChecked) || (feeScaleValue && !isActiveChecked) || (isActiveChecked && feeScaleValue)
  })
}
}
