import { Controller } from "stimulus"

export default class extends Controller {

  selectAllEmail(event){
    let selectCheckBox = event.target;
    if (selectCheckBox.checked){
      $('#allEmail').modal();
    }
  }
}

