import { Controller } from 'stimulus'
import { toggleClasses } from '../lib/helper_functions'
import axios from 'axios'

export default class extends Controller {
  static targets = ["organisationType", "recipients",
                    "organisationName", "userType", "userName",
                    "users", "accounts", "accountEmail", "accountType",
                    "list", "bccMail", "directTab", "bccTab", "bcc", "bccUsers", "toolTypeDiv", "toolType"]
  connect(){
    this.currentAction = $('.nav .active').text().trim() == "New"
    if(this.currentAction ){
      this.directTabTarget.classList.add("active")
      this.bccTarget.value = ''
      this.recipients.value = ''
      this.accountTypeTarget.value = this.organisationType
      $('#user_id').select2({ placeholder: "All" })
      $('#organization_name').select2({ placeholder: "All" })
      $('#user_type').select2({placeholder: "None"})
      $('#tool_type').select2({placeholder: "All"})
    }
  }

  get recipients() {
    if (this.directTabTarget.classList.contains('active')){
      return this.recipientsTarget
    }else {
      return this.bccTarget
    }
  }

  get accounts() {
    if (this.directTabTarget.classList.contains('active')) {
      return this.accountsTarget
    }else {
      return this.bccMailTarget
    }
  }

  get users() {
    if (this.directTabTarget.classList.contains('active')) {
      return this.usersTarget
    }else {
      return this.bccUsersTarget
    }
  }

  get organisationType() {
    return this.organisationTypeTarget.value
  }

  get organisationName() {
    return this.organisationNameTarget.value
  }

  fetchParams(value){
    return (value == 'All') ? '' : value
  }

  elements(data){
    return (data.length > 0) ? data : [data]
  }

  emptyArrayIfNull(value){
    return (value == null) ? [] : value
  }

  toggleButtons(e){
    if (e.target.dataset.tab == "bcc"){
      this.directTabTarget.classList.remove('active')
    } else {
      this.bccTabTarget.classList.remove('active')
    }
    e.target.classList.add('active')
  }

  fetchFilteredList(){
    let params = { 
      params: { 
        organization_type: this.fetchParams(this.organisationType),
        organization_name: this.fetchParams(this.organisationName)
      }
    }
    let list = this.listTarget
    axios.get(`/admins/addon_emails/filtered_emails`, params)
      .then((response)=> {
        list.innerHTML = response.data
      })
  }

  fetchDetails(){
    let url = `/admins/addon_emails/fetch_recipients`
    let orgName = this.currentAction ? this.fetchMultipleIds(this.organisationNameTarget) : this.fetchParams(this.organisationName)
    let params = { params: { organization_type: this.fetchParams(this.organisationType),
                             organization_name: orgName,
                             user_type: this.currentAction ? this.fetchMultipleIds(this.userTypeTarget): '',
                             user_name: this.currentAction ? this.fetchMultipleIds(this.userNameTarget) : '',
                             tool_type: this.currentAction ? this.fetchMultipleIds(this.toolTypeTarget) : ''
                           },
                   headers: {'Content-Type' : 'application/json', 'Accept' : 'application/json'} 
                 }
    return [url, params]
  }

  fetchEmails(){
    let details = this.fetchDetails()
    let data = this.recipients
    let accounts = this.accounts
    let users = this.users

    axios.get(details[0], details[1])
      .then((response)=> {
        this.updateEmailField(response, data, accounts, users)
      })
  }

  fetchUserOptions(){
    if(this.currentAction){
      let attributes = this.loadAttributes()
      let details = this.fetchDetails()
      axios.get(details[0], details[1])
        .then((response)=> {
          this.responseChanges(attributes[0], response.data.user_options)
          this.updateEmailField(response, attributes[1], attributes[2], attributes[3])
        })
    }else{
      this.fetchFilteredList()
    }
  }

  fetchOrganisationOptions(){
    if(this.currentAction){
      this.accountTypeTarget.value = this.organisationType
    }
    let details = this.fetchDetails()
    let firmName = this.organisationNameTarget
    details[1]["params"]["organization_name"] = ""
    details[1]["params"]["user_name"] = ""
    axios.get(details[0], details[1])
      .then((response)=> {
        this.responseChanges(firmName, response.data.organization_options)
        if(this.currentAction){
          this.responseChanges(this.loadAttributes()[0], response.data.user_options)
          this.updateEmailField(response, this.loadAttributes()[1], this.loadAttributes()[2], this.loadAttributes()[3])
        }
      })
  }

  toggleToolTypeDiv() {
    let option = event.target.value
    let [removeClass, addClass] = (option === 'surveying_firm') ? ['d-none', 'd-block'] : ['d-block', 'd-none']
    toggleClasses(this.toolTypeDivTarget, removeClass, addClass)
  }

  loadAttributes(){
    let userName = this.userNameTarget
    let data = this.recipients
    let accounts = this.accounts
    let users = this.users
    return [userName, data, accounts, users]
  }

  updateEmailField(response, data, accounts, users){
    let mailType = this.accountEmailTarget.value
    let responseUsers = this.emptyArrayIfNull(response.data.users)
    let userDetails = this.fetchRecipients(responseUsers, 'user')
    let accountDetails = this.fetchRecipients(response.data.accounts, mailType)
    users.value = userDetails[1]
    if(mailType == 'none') {
      accounts.value = []
      data.value = userDetails[0]
    }else {
      accounts.value = accountDetails[1]
      data.value = accountDetails[0] + userDetails[0]
    }
  }

  fetchRecipients(data, mailType){
    let emails = []
    let ids = []
    this.elements(data).forEach((responseData) => { 
      if(responseData['email'] && responseData['id']){
        if(['user', 'central_email'].includes(mailType)){
          emails += responseData['email'] + ';'
        }
        else{
          emails += responseData['billing_email'] + ';'
        }
        ids += responseData['id'] + ','
      }
    })
    return [emails, ids]
  }

  responseChanges(targetName, options){
    targetName.value = ''
    targetName.innerHTML = ''
    let option = targetName.appendChild(document.createElement('option'))
    option.innerHTML = 'All'
    option.value = ''
    if(options != null && options.length > 0 ){
      this.elements(options).forEach((opt) => {
        let option = targetName.appendChild(document.createElement('option'))
        option.value = opt['id']
        option.innerHTML = opt['name']
      })  
    }
    if(!this.currentAction){
      this.fetchFilteredList()
    }
  }

  fetchUserTypeOptions(){
    if(this.currentAction){
      const firmTypes = this.userTypeTarget.dataset.filterconst.split(',')
      const clientTypes = firmTypes.slice(0,4)
      this.userTypeTarget.innerHTML = ''
      let types = ['surveying_firm', 'all'].includes(this.organisationType) ? firmTypes : clientTypes
      this.addOptions(this.userTypeTarget, types)
    }
  }

  addOptions(options, types){
     types.forEach((type) => {
       let element = options.appendChild(document.createElement('option'))
       element.value = type
       element.innerHTML = this.titleCase(type)
    })
  }

  fetchMultipleIds(list){
    let item_list = []
    let items = list.selectedOptions
    if(items != undefined) {
      for (let i=0; i<items.length; i++) {
        item_list.push(items[i].value)
      }
    }
    return item_list
  }

  titleCase(words){
    return [...words.split('_')].map(word => word[0].toUpperCase() + word.substr(1) ).join(' ')
  }
}
