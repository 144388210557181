import { Controller } from "stimulus"
import { togglePassword } from "../lib/helper_functions"

export default class extends Controller {
  static targets = [ "password" ]

  showPassword() {
    togglePassword(this.passwordTarget)
  }

  disabledSctBtn(e) {
    e.target.classList.add('disabled')
  }
}
