import { Controller } from "stimulus"
import axios from 'axios'
import LoadMapController from './load_map_controller'

export default class extends LoadMapController {
  static targets = ["mapData"]
  static values = { locations: Array }

  connect() {
    this.showMapView(-0.999, 53.000)
    this.initializeVariable()
  }

  onMapLoaded() {
    this.getGeojsonData(this.locationsValue)
  }

  addMarkerOnMap(instructiongeojson) {
    instructiongeojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = 'instruct-marker'
      // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      this.panelFeature.createMarker(this.map, el, marker)
      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, marker, this.map)
      })

      return el.addEventListener('click', (e) => {
        // 1. Fly to the point
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
        this.showInstruction(marker)
      })
    })
  }

  showInstruction(currentFeature) {
    let accountId = this.mapDataTarget.dataset.accountid
    let quotation = this.mapDataTarget.dataset.isquotation
    if (this.context.element.className == "admin-legacy-view"){
      window.location =  `/admins/legacy_instructions/${currentFeature.properties.id}`
    }
    else if (!this.context.element.classList.contains('admin-map-view')){
      let path = (quotation == 'true') ? 'quotations' : 'instructions'
      window.location =  `/accounts/${accountId}/${path}/${currentFeature.properties.id}`
    }
    else{
      window.location = `/admins/instructions/${currentFeature.properties.id}`
    }
  }
}
