import { Controller } from "stimulus"
import { DataTable } from 'simple-datatables'

export default class extends Controller {

  connect(){
    $(document).ready(() => {
      this.invoicesDataTable()
    })
  }

  invoicesDataTable() {
    new DataTable("#invoiceTable", {
      columns: [
        { select: 2, sortable: false }
      ]
    })
  }
}
