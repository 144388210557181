import { Controller } from "stimulus"
import axios from 'axios'
import LoadMapController from './load_map_controller'

export default class extends LoadMapController {
  static values = { locations: Array }

  connect() {
    this.showMapView(-0.999, 53.000)
    this.initializeVariable()
  }

  onMapLoaded() {
    this.getGeojsonData(this.locationsValue)
  }

  addMarkerOnMap(officegeojson) {
    officegeojson.features.forEach(marker => {
      const el = document.createElement('div')
      el.className = 'marker'
      // By default the image for your custom marker will be anchored by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      this.panelFeature.createMarker(this.map, el, marker)
      el.addEventListener('mouseenter', (e) => {
        this.infobox.createPopUp(el, marker, this.map)
      })

      return el.addEventListener('click', (e) => {
        // 1. Fly to the point
        this.panelFeature.flyToPanel(this.map, marker.geometry.coordinates)
      })
    })
  }
}
