import { Controller } from "stimulus"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
export default class extends Controller {
  static targets = ["address1", "address2", "city", "country", "postcode", "geocoder", "signUpLocation"]

  static values = { irelandRegion: Boolean }

  connect(){
    this.showSearchBar()
  }

  showSearchBar(){
    let address1 = this.address1Target
    let address2 = this.address2Target
    let city = this.cityTarget
    let country = this.hasCountryTarget ? this.countryTarget : null;
    let postcode = this.postcodeTarget
    let geocoder = new MapboxGeocoder({ // Initialize the geocoder
      accessToken: mapboxgl.accessToken, // Set the access token
      mapboxgl: mapboxgl, // Set the mapbox-gl instance
      countries: this.setRegion(),
      placeholder: 'Find the address...'
    });
    this.setValues(geocoder, address1, address2, city, country, postcode)
  }

  setValues(geocoder, address1, address2, city, country, postcode) {
    this.geocoderTarget.appendChild(geocoder.onAdd())
    geocoder.on('result', (result) => {
      let place = result.result
      let address = place.place_name.split(",")
      address1.value = address[0]
      address2.value = this.formatAddress2(address, address2)
      place.context.map((obj) => {
        this.setCountryAddress(obj, city, country, postcode)
      })
    })
  }

  setCountryAddress(obj, city, country, postcode) {
    if (this.irelandRegionValue) {
      this.setIrelandAddress(obj, city, country, postcode)
    } else {
      this.setUkAndOtherCountryAddress(postcode, country, city, obj)
    }
  }

  setIrelandAddress(obj, city, country, postcode) {
    if (obj.id.includes('place')) {
      return city.value = obj.text
    }
    this.setPostcode(obj, postcode)
    this.setCountry(obj, country)
  }

  setUkAndOtherCountryAddress(postcode, country, city, obj) {
    this.setPostcode(obj, postcode)
    this.setCountry(obj, country)
    if (obj.id.includes('district')) {
      return city.value = obj.text
    }
  }

  setPostcode(obj, postcode) {
    if (obj.id.includes('postcode')) {
      return postcode.value = obj.text
    }
  }

  setCountry(obj, country) {
    if (country && obj.id.includes('country')) {
      return country.value = obj.text
    }
  }

  formatAddress2(address, address2) {
    return (!this.irelandRegionValue || address.length > 4) ? address[1].trim() : address2.value
  }

  setRegion() {
    if (this.hasSignUpLocationTarget) return ''

    return this.irelandRegionValue ? 'ie' : 'gb'
  }
}
