import { Controller } from "stimulus"
import { getMetaValue, requestHeaders } from '../lib/helper_functions'

export default class extends Controller {
  static values = { customNotificationId: Number, accountId: Number }

  toggleStatus(event) {
    const headers = {
      ...requestHeaders,
      'X-CSRF-Token': getMetaValue('csrf-token')
    }
    fetch(`/accounts/${this.accountIdValue}/custom_notifications/${this.customNotificationIdValue}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
            status: event.target.checked
        })
    })
    .then(response => {
      return response.json()
    })
  }

  createCustomNotification() {
    const headers = {
      ...requestHeaders,
      'X-CSRF-Token': getMetaValue('csrf-token')
    }
    fetch(`/accounts/${this.accountIdValue}/custom_notifications`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          custom_notification_type: "Lead"
        })
    })
    .then(response => {
      return response.json()
    }) .then(data => {
      window.location.reload()
    })
  }
}
