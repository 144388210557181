import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "options", "choice" ]

  connect(){
    this.updateOptionsDisplay()
  }

  updateOptionsDisplay(){
    if (this.choiceTarget.value == 'single_choice_dropdown'){
      this.optionsTarget.style.display = "block";
    } else {
      this.optionsTarget.style.display = "none";
    }
  }
}
