import { Controller } from 'stimulus'
import { applyMixins } from '../lib/helper_functions'
import { EventCalendar } from './mixins/event_calendar'
import { merge } from 'lodash'

export default class extends Controller {
  static targets = [ 'commentBox', 'dateBox', 'triedCheckbox', 'inspectionDate', 'inspectionStart', 'inspectionEnd', 'updateInspectionButton' ]
  static values = { url: String, surveyors: Array, inspection: Object }

  connect() {
    applyMixins(this, [EventCalendar])
    this.toggelContents()
    this.fetchInspections()
  }

  toggelContents() {
    if (this.triedCheckboxTarget.checked) {
      this.commentBoxTarget.required = true
      this.commentBoxTarget.style.display = 'block'
      this.dateBoxTarget.style.display = 'none'
    } else {
      this.commentBoxTarget.style.display = 'none'
      this.dateBoxTarget.style.display = 'block'
    }
  }

  fetchInspections() {
    fetch(this.urlValue, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.json())
    .then((data) => {
      this.initializeCalendar({ resources: this.surveyorsValue, events: data['events'], resourceKey: 'surveyor_id', resourceName: 'surveyors', hideEditEventButton: true })
      this.visualizeInspection()
    })
  }

  buildInspectionEvent(eventId, resourceId) {
    let inspectionDate = new Date(this.inspectionDateTarget.value)
    let startDate = new Date(`${inspectionDate.toDateString()} ${this.inspectionStartTarget.value} GMT`)
    let endDate = new Date(`${inspectionDate.toDateString()} ${this.inspectionEndTarget.value} GMT`)

    return merge(this.inspectionValue, { id: eventId, resourceId: resourceId, start: startDate.toISOString(), end: endDate.toISOString() })
  }

  visualizeInspection() {
    let resourceId = this.inspectionValue.surveyor_id
    let eventId = this.inspectionValue.id
    let event = this.buildInspectionEvent(eventId, resourceId)
    this.visualizeEvent(event, resourceId)
  }

  enableUpdateButton() {
    this.updateInspectionButtonTarget.disabled = false
  }
}
