import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import XHRUpload from '@uppy/xhr-upload'
import Compressor from '@uppy/compressor'

import { getMetaValue, toggleClasses } from '../../lib/helper_functions'

export const FileUploader = {
  imgTypes:  ['image/png', 'image/jpeg'],
  appendixTypes: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],

  initializeUploader(url, options = {}) {
    let uppyClient = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: 20971520, //20 MB
        maxNumberOfFiles: options.maxNumberOfFiles || 50,
        allowedFileTypes: this.imgTypes.concat(this.appendixTypes)
      }
    })
    
    this.initUploadDashboard(uppyClient, url, options)
    this.uppyEventListeners(uppyClient, options)
  },

  initUploadDashboard(uppyClient, url, options = {}) {
    let targetId = options.target || 'uploader-area'
    let htmlData = `<div id="uploader-data" class="d-none" data-modal-id="${options.modalId}" data-redirect-url="${options.redirectUrl}"></div>`
    document.getElementById(targetId).innerHTML = htmlData

    uppyClient.use(Dashboard, {
      target: `#${targetId}`,
      inline: true,
      height: options.height || 450,
      metaFields: [
        { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
      ]
    }).use(Compressor, {
      quality: Number(options.quality || 0.6),
      maxHeight: Number(options.maxHeight || 1000)
    }).use(XHRUpload, {
      headers: { 'X-CSRF-Token': getMetaValue('csrf-token') },
      fieldName: 'file',
      endpoint: url
    })
  },

  uppyEventListeners(uppyClient, options) {
    uppyClient.on('file-added', (file) => {
      this.setFileMetadata(uppyClient, file)
    })

    uppyClient.on('upload', (data) => {
      this.addOrRemoveEventListener(true)
      let errorDiv = document.getElementById('upload-error-div')
      toggleClasses(errorDiv, 'd-block', 'd-none')
      document.getElementById('upload-error-list').innerHTML = ''
      $('.collapse').collapse('hide')
    })

    uppyClient.on('upload-error', (file, error, response) => {
      let errorItem = document.createElement('li')
      let msg = (response.status === 422) ? response.body.message : 'Upload error'
      errorItem.innerHTML = `${file.name} : ${msg}`
      document.getElementById('upload-error-list').appendChild(errorItem)
    })

    uppyClient.on('complete', (result) => {
      this.addOrRemoveEventListener()
      if(result.failed.length === 0){
        this.performOnCompletionSuccess(options)
      } else {
        let errorDiv = document.getElementById('upload-error-div')
        toggleClasses(errorDiv, 'd-none', 'd-block')
      }
    })
  },

  performOnCompletionSuccess(options) {
    if(options.modalId)
      $(`#${options.modalId}`).modal('hide')

    if(options.redirectUrl)
      Turbolinks.visit(options.redirectUrl)

    if(options.xhrRequestUrl)
      $.get({
        url: options.xhrRequestUrl,
        headers: { 'Content-Type': 'text/javascript', 'X-CSRF-Token': getMetaValue('csrf-token') }
      })
  },

  leavingPage(event) {
    const message = 'Any uploads pending or in-progress may get cancelled. Are you sure you want to leave this page?'
    if (event.type === 'turbolinks:before-visit') {
      if (!window.confirm(message)) {
        event.preventDefault()
      }
    } else {
      event.returnValue = message
      return event.returnValue
    }
  },

  addOrRemoveEventListener(addListener = false) {
    if (addListener) {
      window.addEventListener('beforeunload', this.leavingPage)
      window.addEventListener('turbolinks:before-visit', this.leavingPage)
    } else {
      window.removeEventListener('beforeunload', this.leavingPage)
      window.removeEventListener('turbolinks:before-visit', this.leavingPage)
    }
  },

  setFileMetadata(uppyClient, file) {
    uppyClient.setFileMeta(file.id, {
      last_modified_at: new Date(file.data.lastModified)
    })
  }
}
