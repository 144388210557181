import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["activeClientCount", "activeYears"]
  connect(){
    $(document).ready(() => {
      this.counter("count1", 0, this.activeYearsTarget.value, 3000);
      this.counter("count2", 100, this.activeClientCountTarget.value, 2500);
    });
  }
  
  counter(id, start, end, duration) {
    let obj = document.getElementById(id),
    current = start,
    range = end - start,
    increment = end > start ? 1 : -1,
    step = Math.abs(Math.floor(duration / range)),
    timer = setInterval(() => {
    current += increment;
    obj.textContent = current;
    if (current == end) {
      clearInterval(timer);
    }
    }, step);
  }
}
