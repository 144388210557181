import { Controller } from "stimulus"

export default class extends Controller  {

  connect() {
    this.markerWidth = 0
    this.markerHeight = -23
    this.markerRadius = this.markerWidth / 2
  }

  createPopUp(el, marker, map){
    let linearOffset = Math.round(Math.sqrt(0.5 * Math.pow(this.markerRadius, 2)))

    this.popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: { 'top': [0, 0],
                'top-left': [0, 0],
                'top-right': [0, 0],
                'bottom': [0, -36],
                'bottom-left': [linearOffset, (this.markerHeight - this.markerRadius + linearOffset) * -1],
                'bottom-right': [-linearOffset, (this.markerHeight - this.markerRadius + linearOffset) * -1],
                'left': [this.markerRadius, (this.markerHeight - this.markerRadius) * -1],
                'right': [-this.markerRadius, (this.markerHeight - this.markerRadius) * -1]
              }
    })
    map.getCanvas().style.cursor = 'pointer'

    let coordinates = marker.geometry.coordinates
    let officeIndex = document.getElementsByClassName("geo-map-view")
    let infoContent
    infoContent = officeIndex.length == 0 ? marker.properties.description : marker.properties.name || marker.place_name
    this.popup.setLngLat([coordinates[0], coordinates[1]])
      .setHTML('<div>'+ infoContent + '</div>')
      .addTo(map)
    // remove info box popup on mouse leave
    this.removeInfoBox(el, map) 
  }

  removeInfoBox(el, map){
    el.addEventListener('mouseleave', (e) => {
      map.getCanvas().style.cursor = ''
      this.popup.remove()
    })
  }

  getPosition(coords, km, points) {
    const ret = []
    const distanceX = km / (111.320 * Math.cos((coords.latitude * Math.PI) / 180))
    const distanceY = km / 110.574
    let x,y,theta
    let i = 0
    while (i < points) {
      theta = (i / points) * 2 * Math.PI
      x = distanceX * Math.cos(theta)
      y = distanceY * Math.sin(theta)
      ret.push([coords.longitude + x, coords.latitude + y])
      i++
    }
    ret.push(ret[0])
    return ret
  }

  createGeoJSONCircle(center, radiusInMile, points) {
    if (!points) points = 64
    const coords = { 
      latitude: center[1],
      longitude: center[0]
    }
    const km = radiusInMile * 1.609344
    const ret = this.getPosition(coords, km, points)
      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [ {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': [ ret ]
            }
        } ]
      }
    }
  }

  createRadiusArea(map) {
    map.addLayer({
      'id': 'polygon',
      'type': 'line',
      'source': 'polygon',
      'layout': {},
      'paint': {
        'line-color': 'red'
      }
    })
  }

  createInfoboxOnMouseEnterEvent(element, marker, map){
    element.addEventListener('mouseenter', (e) => {
      this.createPopUp(element, marker, map)
    })
  }
}
