import { Controller } from "stimulus"
import { togglePassword, toggleClasses, clearInputFields, getMetaValue, clipboardCopyHandler } from "../lib/helper_functions"


export default class extends Controller {
  static targets = [ 'password', 'usingToken', 'userCredentials' ]
  
  showPassword() {
    togglePassword(this.passwordTarget)
  }

  getAuthenicateType(e) {
    if (e.target.value === 'token') {
      toggleClasses(this.usingTokenTarget, 'd-none', 'd-block')
      toggleClasses(this.userCredentialsTarget, 'd-flex', 'd-none')
      clearInputFields(this.userCredentialsTarget)
    } else {
      toggleClasses(this.usingTokenTarget, 'd-block', 'd-none')
      toggleClasses(this.userCredentialsTarget, 'd-none', 'd-flex')
      clearInputFields(this.usingTokenTarget)
    }
  }

  toggleInstructionApiSetting(e) {
    fetch(e.target.dataset.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getMetaValue('csrf-token')
    }
  })
  }
}
