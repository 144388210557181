import { Controller } from "stimulus"
import { togglePassword } from "../lib/helper_functions"
import { toggleClasses } from '../lib/helper_functions'
import { getMetaValue } from '../lib/helper_functions'

export default class extends Controller {
  static targets = [ 'password', 'keyPropertyFactsPages', 'desktopResearchPages', 'spriftSpinner' ]

  showPassword() {
    togglePassword(this.passwordTarget)
  }

  getPropertyTypeSetPages(e) {
    if (e.target.value === '3') {
      toggleClasses(this.keyPropertyFactsPagesTarget, 'd-none', 'd-flex')
      toggleClasses(this.desktopResearchPagesTarget, 'd-flex', 'd-none')
    } else {
      toggleClasses(this.keyPropertyFactsPagesTarget, 'd-flex', 'd-none')
      toggleClasses(this.desktopResearchPagesTarget, 'd-none', 'd-flex')
    }
  }

  toggleSpinner(e) {
    toggleClasses(this.spriftSpinnerTarget, 'd-none', 'd-flex')
    e.target.classList.add('disabled')
  }

toggleSpriftAttachment(e) {
  const params = {document_type: e.target.id }
  fetch(e.target.dataset.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getMetaValue('csrf-token')
    },
    body: JSON.stringify(params)
  })
}
}
