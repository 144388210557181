import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "applicantName", "applicantContact", "accessName", "accessContact",
                     "accessAddress1", "accessAddress2", "accessCity", "accessPostcode",
                     "accessEmailAddress", "surveyAddress1", "surveyAddress2", "surveyPostcode",
                     "surveyCity", "privateClient", "showList", "billingContact",
                     "billingEmail", "applicantEmail", "clientName", "clientEmail",
                     "billingAccount", "billingInstruction", "applicantTitle"]

  copy(){
    if (this.hasPrivateClientTarget && this.privateClientTarget.value != "" && this.hasShowListTarget && this.showListTarget.classList.contains("d-block")) {
      let client = this.privateClientTarget
      let selectedClient = client.options[client.selectedIndex]
      this.accessNameTarget.value = selectedClient.dataset.title + " " + selectedClient.text
      this.accessContactTarget.value = selectedClient.dataset.contact
      this.accessEmailAddressTarget.value = selectedClient.dataset.email
    }else {
      this.accessNameTarget.value = this.applicantTitleTarget.value + " " + this.applicantNameTarget.value;
      this.accessContactTarget.value = this.applicantContactTarget.value;
      this.accessEmailAddressTarget.value = this.applicantEmailTarget.value;
    }
  }

  copySurveyAddress(){
    this.accessAddress1Target.value = this.surveyAddress1Target.value;
    this.accessAddress2Target.value = this.surveyAddress2Target.value;
    this.accessPostcodeTarget.value = this.surveyPostcodeTarget.value;
    this.accessCityTarget.value = this.surveyCityTarget.value;
  }

  copyBillingDetails(){
    this.billingContactTarget.value = this.applicantTitleTarget.value + " " + this.applicantNameTarget.value;
    this.billingEmailTarget.value = this.applicantEmailTarget.value;
    this.billingAccountTarget.checked = false;
    this.billingInstructionTarget.value = ''
  }
}
