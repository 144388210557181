import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["postcodeCoverages", "postcode"]

  addOrRemovePostCode(e){
    let coverages = this.postcodeCoveragesTarget.value.split(" ")
    let postcode = this.postcodeTarget.value.trim().toUpperCase()
    let idx = coverages.indexOf(postcode)
    let postcodeEvent = e.target.dataset.event
    if(postcodeEvent == 'add' && idx == -1){
      coverages.push(postcode)
      coverages.sort()
    } else if (postcodeEvent == 'remove' && idx != -1) {
        coverages.splice(idx, 1)
    }
    this.postcodeCoveragesTarget.value = coverages.join(" ").trim()
  }
}
