import { Controller } from 'stimulus'
import { getMetaValue, applyMixins } from '../lib/helper_functions'
import { FieldsHelper } from './mixins/fields_helper'

export default class extends Controller {
  static targets = [ 'recordType', 'recordId', 'account' ]

  connect() {
    applyMixins(this, [FieldsHelper])
  }

  // To update fields value
  fieldUpdate(e){
    let fieldId = e.target.getAttribute('data-id')
    let value = e.target.value;
    let content = { field: { value: value } }
    let hasErrors = e.target.nextElementSibling?.matches('.parsley-errors-list.filled');
    if((value != null) && (e.target.name === 'rating' || !hasErrors)){
      this.updateData(fieldId, this.recordIdTarget.value, content)
    }
  }

  // To update filed with N/A
  updateNotApplicable(e){
    let field = e.target.previousElementSibling.firstElementChild
    let data = this.notApplicableChanges(e, field)
    this.updateData(data[0], this.recordIdTarget.value, data[1])
  }

  getUrl(fieldId, recordId) {
    if(this.recordTypeTarget.value == 'SurveyType') {
      let accountId = this.accountTarget.value
      return `/admins/accounts/${accountId}/survey_types/${recordId}/fields/${fieldId}/update_value`
    } else {
      return `/admins/audit_templates/${recordId}/fields/${fieldId}/update_value`
    }
  }

  updateData(fieldId, recordId, content){
    fetch(this.getUrl(fieldId, recordId), {
      headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': getMetaValue('csrf-token')
            },
      method: 'POST',
      body: JSON.stringify(content)
    })
    .then(response => response.json())
    .then((data) => {
      let fieldId = data['field_id']
      let button = document.getElementById('report-btn')
      let link = document.getElementById(`list-field-${fieldId}`)
      button.textContent = `${data['incompleted_count']} o/s fields`
      if(link !== null){  
        link.style.display = 'none'
      }
    })
  }
}
