import { Controller } from 'stimulus'
import { disableButton } from '../lib/helper_functions'

export default class extends Controller {

  static targets = ['paymentStatus', 'datePaidField', 'paymentFields',
    'paymentReference', 'termsAccept', 'getClientName'
  ]

  connect() {
    const queryParams = new URLSearchParams(window.location.search)
    const targetParam = 'session_back'
    if (queryParams.has(targetParam)) {
      this.showContent()
      window.addEventListener('popstate', this.showContent.bind(this))
    }
  }

  showContent() {
    const button = document.querySelector('button[data-target="#survey-type-list"]')
    if (button) {
      button.click()
    }
  }

  disableAcceptButton() {
    const button = this.element
    disableButton(button)
  }

  validateClientFields(){
    let requiredValue = event.target.dataset.require == 'true' ? true : false
    this.getClientNameTarget.required = requiredValue
    this.termsAcceptTarget.required = requiredValue
  }

  togglePaymentFields() {
    const selectedStatus = this.paymentStatusTarget.value
    const paymentReference  = this.paymentReferenceTarget
    const datePaid = this.datePaidFieldTarget
    const paymentFields = this.paymentFieldsTarget

    if (selectedStatus == 'true') {
      paymentFields.style.display = 'block'
      datePaid.setAttribute('required', 'true')
      datePaid.value ||= new Date().toISOString().slice(0, 10)
      paymentReference.setAttribute('required', 'true')
    } else {
      paymentFields.style.display = 'none'
      datePaid.removeAttribute('required')
      datePaid.value = ''
      paymentReference.removeAttribute('required')
    }
  }
}
